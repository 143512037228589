<template>
    <div class="card-header">
        <div class="d-flex flex-row justify-content-between align-content-center">

            <create-button
                :text="text"
                @showModal="$emit('showModal', text.createModalTitle)"
            />

            <h3 class="card-title text-dark">{{ text.cardTitle }}</h3>

            <card-tools/>

        </div>
    </div>
</template>

<script>
    import CreateButton from './HeaderComponents/CreateButton';
    import CardTools from './HeaderComponents/CardTools';

    export default {
        name: "CreateModalComponent",
        props: ['text'],
        components: {
            'create-button': CreateButton,
            'card-tools': CardTools
        }
    }
</script>

<style scoped>

</style>
