/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');
require('./bus-generator');
require('./global-varibles');

window.Vue = require('vue');

import Vue from 'vue'
import store from './store'
import router from './routes';
import Swal from "sweetalert2";
import Vuelidate from 'vuelidate';
import VModal from 'vue-js-modal';
import VueRouter from "vue-router";
import Form from "./utilities/Form";
import underscore from 'vue-underscore';
import LaravelPermissions from 'laravel-permissions';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
const VueInputMask = require('vue-inputmask').default

window.Vue = Vue;
window.Form = Form;
window.Swal = Swal;

Vue.use(VueInputMask);
Vue.use(VueRouter);
Vue.use(underscore);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VModal, { dynamic: true, injectModalsContainer: true });
Vue.use(Vuelidate);
Vue.use(require('vue-moment'));
Vue.use(LaravelPermissions);


/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))
/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: '#app',
    store,
    router
});
