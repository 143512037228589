<template>
    <div class="modal-content">
        <div class="modal-header">
            <h4 class="modal-title">{{ title }}</h4>
            <button @click="$emit('close')" aria-label="Close" class="close" type="button">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">

            <!-- Инфо клиента -->
            <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab">
                    <div class="d-flex flex-row justify-content-around align-content-stretch flex-wrap p-2">
                        <h4 class="font-weight-bold">{{ client.fullName }}</h4>
                        <h5 class="font-weight-bold">{{ client.number }}</h5>
                    </div>
                </b-card-header>
            </b-card>

            <!-- Список расписаний -->
            <div class="mt-5" role="tablist">

                <b-card no-body class="mb-1"
                        v-for="order in orders"
                        :key="order.id">
                    <!--                    <router-link-->
                    <!--                        :to="{ path: `schedule/${schedule.id}`, props: { schedule: schedule }}"-->
                    <!--                    >-->
                    <b-card-header header-tag="header" class="p-1" role="tab" >

                        <b-button block variant="light"
                                  v-b-toggle="`accordion-${order.id}`"
                                  :class="{'is_deleted': order.deleted_at !== null}"
                                  @click="redirectToBookings(order)"
                        >

                            <!--Header schedule info-->
                            <div class="d-flex flex-row justify-content-between align-content-stretch flex-wrap p-2">
                                <div class="d-flex flex-column flex-nowrap justify-content-around mr-6">
                                    <h4 class="font-weight-bold">
                                        {{order.route.location_start.name}} - {{order.route.location_end.name}}
                                    </h4>
                                    <h6>{{order.schedule.bus.numeric}}</h6>
                                </div>

                                <div class="d-flex flex-column flex-nowrap justify-content-around mr-6">
                                    <h6 class="font-weight-bold">Відправлення</h6>
                                    <p class="date mr-2">{{ new Date(order.departure_at).toLocaleString() }}</p>
                                </div>

                                <div class="d-flex flex-column flex-nowrap justify-content-around mr-6">
                                    <h6 class="font-weight-bold">Прибуття</h6>
                                    <p class="date mr-2">{{ new Date(order.arrival_at).toLocaleString() }}</p>
                                </div>

                                <div class="d-flex flex-column flex-nowrap justify-content-around mr-6">
                                    <h6 class="font-weight-bold">Місце</h6>
                                    <p class="date mr-2">{{ order.seat_index }}</p>
                                </div>

                                <div class="d-flex flex-column flex-nowrap justify-content-around mr-6" @click="updateComment(order)">
                                    <h6 class="font-weight-bold">Коментар</h6>
                                    <p class="date mr-2">{{ order.comment }}</p>
                                </div>

                                <div class="d-flex flex-column flex-nowrap justify-content-around mr-6" @click="updateDispatcherComment(order)">
                                    <h6 class="font-weight-bold">Коментар блокування</h6>
                                    <p class="date mr-2">{{ order.dispatcher_comment }}</p>
                                </div>
                            </div>

                        </b-button>

                    </b-card-header>
                    <!--                    </router-link>-->

                    <!--                    <b-collapse :id="`accordion-${schedule.id}`" accordion="my-accordion" role="tabpanel">-->
                    <!--                        <b-card-body>-->
                    <!--                            <b-card-text>{{ text.cardTitle }}</b-card-text>-->
                    <!--                        </b-card-body>-->
                    <!--                    </b-collapse>-->

                </b-card>

            </div>

        </div>

        <!--Create button-->
<!--        <div class="modal-footer">-->
<!--            <button-->
<!--                class="btn btn-default" type="button"-->
<!--                @click="$emit('close')">-->
<!--                {{ ('Отмена') }}-->
<!--            </button>-->
<!--            <button class="btn btn-info" type="button"-->
<!--                    @click="create">-->
<!--                {{ ('Забронировать') }}-->
<!--            </button>-->
<!--        </div>-->
    </div>
</template>

<script>
    import {ModelListSelect} from 'vue-search-select';
    import BusGenerator from '../busgen';

    export default {
        name: "orderModal",
        props: ['client', 'title'],

        components: {
            // 'model-list-select': ModelListSelect,
            // 'bus-generator': BusGenerator,
        },

        data() {

            return {
                orders: ''
            }
        },

        mounted() {
            axios
                .post(`/admin/orders/get-by-client`, {
                    client_id: this.client.id
                })
                .then(({data}) => {
                    this.orders = Object.values(data.DATA.orders).sort((a, b) => {
                        return new Date(b.departure_at) - new Date(a.departure_at);
                    });
                });
        },

        methods: {
            getPrice() {
                if(!this.location_start_id || !this.location_end_id) {
                    return;
                }

                axios
                    .post('/admin/routes/get-by-locations', {
                        'schedule_id': this.schedule.id,
                        'location_start_id': this.location_start_id,
                        'location_end_id': this.location_end_id
                    })
                    .then(({data}) => {
                        this.route = data.DATA;
                        this.form.route_id = data.DATA.id
                    })
            },

            getClient(number) {
                axios
                    .post(`/admin/clients/get-by-number/${number}`)
                    .then(({data}) => {
                        if (data.DATA){
                            this.client = data.DATA
                            this.client.name = data.DATA.fullName
                            this.form.client_id = data.DATA.id
                        }
                    })
            },

            create() {
                axios
                    .post('/admin/orders/store', {
                        'order': this.form,
                        'client': this.client
                    })
                    .then(({data}) => {
                        // this.$emit('close');
                        Swal.fire('Збережено!');
                        location.reload();
                    })
            },

            updateComment(order) {
                Swal.fire({
                    title: 'Коментар',
                    input: 'text',
                    inputValue: order.comment,
                    showCancelButton: true,
                    confirmButtonText: 'Зберегти',
                    cancelButtonText: 'Відміна',
                }).then((result) => {
                    if (result.value !== undefined && result.value !== order.comment) {
                        Swal.fire({title: 'Оновлення'});
                        Swal.showLoading();
                        axios
                            .post(`/admin/orders/${order.id}/comment`, {
                                'comment': result.value,
                            })
                            .then(({data}) => {
                                // this.$emit('close');
                                order.comment = data.DATA.comment;
                                if (this.client.last_comment && this.client.last_comment.id === order.id) {
                                    this.client.last_comment.comment = order.comment;
                                }
                                Swal.fire('Збережено!');
                            })
                            .catch(function(error) {
                                if (error.response) {
                                    Swal.fire({
                                        icon: "error",
                                        title: "Помилка, спробуйте пізніше",
                                    });
                                }
                            });
                    }
                });
            },

            updateDispatcherComment(order) {
                Swal.fire({
                    title: 'Коментар блокування',
                    input: 'text',
                    inputValue: order.dispatcher_comment,
                    showCancelButton: true,
                    confirmButtonText: 'Зберегти',
                    cancelButtonText: 'Відміна',
                }).then((result) => {
                    if (result.value !== undefined && result.value !== order.dispatcher_comment) {
                        Swal.fire({title: 'Оновлення'});
                        Swal.showLoading();
                        axios
                            .post(`/admin/orders/${order.id}/dispatcher-comment`, {
                                'comment': result.value,
                            })
                            .then(({data}) => {
                                // this.$emit('close');
                                order.dispatcher_comment = data.DATA.comment;
                                if (this.client.last_comment && this.client.last_comment.id === order.id) {
                                    this.client.last_comment.dispatcher_comment = order.dispatcher_comment;
                                }
                                Swal.fire('Збережено!');
                            })
                            .catch(function(error) {
                                if (error.response) {
                                    Swal.fire({
                                        icon: "error",
                                        title: "Помилка, спробуйте пізніше",
                                    });
                                }
                            });
                    }
                });
            },
            redirectToBookings(order){
                let schedule = order.schedule;
                schedule.departure_at = this.combineDateAndTime(order.departure_at, order.route.departure_at);
                schedule.arrival_at = this.combineDateAndTime(order.arrival_at, order.route.arrival_at);
                schedule.location_start_name = order.schedule.main_route.location_start.name;
                schedule.location_end_name = order.schedule.main_route.location_end.name;
                schedule.bus_numeric = order.schedule.bus.numeric;
                schedule.type_id = order.schedule.bus.type_id;
                schedule.bus_schema = order.schedule.bus.bus_type.schema;
                sessionStorage.setItem("schedule", JSON.stringify(schedule));
                window.location.href = "bookings";
            },
            combineDateAndTime(dateString, timeString) {
                const date = new Date(dateString);
                const time = new Date(timeString);

                const combined = new Date(
                        date.getFullYear(),
                        date.getMonth(),
                        date.getDate(),
                        time.getHours(),
                        time.getMinutes(),
                        time.getSeconds()
                );

                return combined.toISOString();
            }
        }
    }
</script>

<style scoped>
.is_deleted {
    background: #e6b1b1;
}
</style>
