<template>
    <div class="card settings-card">

        <div class="card-body table-responsive">
            <table class="table table-bordered table-hover">
                <thead>
                <tr>
                    <th>{{ ('ID') }}</th>
                    <th>{{ ('Назва') }}</th>
                    <th>{{ ('Опис') }}</th>
                    <th>{{ ('Ключ') }}</th>
                    <th>{{ ('Тип') }}</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="setting in settings">
                    <td>{{ setting.id }}</td>
                    <td>{{ setting.name }}</td>
                    <td>{{ setting.description }}</td>
                    <td>{{ setting.key }}</td>
                    <td>{{ setting.field_type }}</td>
                    <td>
                        <div class="container-fluid">
                            <div class="row align-items-center">
                                <div class="col-6 offset-6">
                                    <div class="d-flex flex-row flex-wrap justify-content-end">
                                        <a @click="edit(setting)" class="btn btn-app btn-app_icon">
                                            <i class="fas fa-edit"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <!-- /.card-body -->

    </div>
    <!-- /.card -->
</template>

<script>
    import SettingModal from './modal';

    export default {
        components: {
            SettingModal,
        },
        data() {

            return {
                text: {
                    cardTitle: 'Список налаштувань',
                    updateModalTitle: 'Редагувати налаштування',
                },
                settings: []
            }

        },

        created() {
            this.loadData();
        },

        methods: {
            loadData() {
                axios.post('/admin/settings')
                    .then(({data}) => {
                        this.settings = data.DATA.settings
                    });
            },

            edit(setting) {
                this.showModal(this.text.updateModalTitle, setting);
            },


            showModal(title, setting = null) {
                this.$modal.show(
                    SettingModal, //vue component
                    {   //component props
                        title: title,
                        setting: setting,
                        loadData: this.loadData
                    }, {   //modal settings
                        height: 'auto',
                        draggable: false,
                    }
                );
            },
        }
    }

</script>
