<template>
    <div class="card user-card">

        <card-header :text="this.text" @showModal="showModal"/>
        <!-- /.card-header -->

        <div class="card-body table-responsive">
            <table class="table table-bordered table-hover">
                <thead>
                <tr>
                    <th>{{ ('ID') }}</th>
                    <th>{{ ('Ім’я') }}</th>
                    <th>{{ ('Номер') }}</th>
                    <th>{{ ('Посада') }}</th>
                    <th>{{ ('Перевізник') }}</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="user in users">
                    <td>{{user.id}}</td>
                    <td>{{user.name}}</td>
                    <td>{{user.number}}</td>
                    <td>
                        <p v-for="role in user.roles">
                            {{role.name}}
                        </p>
                    </td>
                    <td>
                        {{ user.carrier != null ? user.carrier.name : '' }}
                    </td>
                    <td>
                        <div class="container-fluid">
                            <div class="row align-items-center">
                                <div class="col-6">
                                    <button type="button" class="btn btn-danger" @click.prevent="resetPassword(user.id)">Скинути пароль</button>

                                </div>
                                <div class="col-6">
                                    <ud-buttons
                                        :data="user"
                                        @onDelete="onDelete"
                                        @onEdit="onEdit"
                                    />
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <!-- /.card-body -->

    </div>
    <!-- /.card -->
</template>

<script>

    import UserModal from './modal';
    import CardHeader from '../../components/Table/CardHeader';
    import udButtons from "../../components/udButtons";

    // import {VuejsDatatableFactory} from 'vuejs-datatable';


    export default {
        components: {
            UserModal,
            // 'datatable': VuejsDatatableFactory,
            'card-header': CardHeader,
            'ud-buttons': udButtons
        },
        data() {

            return {
                text: {
                    createButtonText: 'Додати користувача',
                    cardTitle: 'Список користувачів',
                    createModalTitle: 'Створити нового користувача',
                    updateModalTitle: 'Редагувати користувача',
                },
                users: [{
                    id: '',
                    name: '',
                    number: '',
                    roles: [{
                        id: '',
                        name: '',
                    }],
                    carriers: [{
                        id: null,
                        name: ''
                    }]
                }],
                roles: [{
                    id: '',
                    name: ''
                }],
                carriers: [{
                    id: null,
                    name: ''
                }]
            }

        },

        created() {
            this.loadData();
        },

        methods: {
            loadData() {
                axios.post('/admin/users')
                    .then( ({data}) => this.users = data.reverse() );

                axios.post('/admin/roles')
                    .then( ({data}) => this.roles = data );

                axios.post('/admin/carriers')
                    .then(({data}) => this.carriers = data.carriers)
            },
            pushToTable(event) {
                this.users.push(event.user);
            },
            editInTable(user) {
                let objIndex = this.users.findIndex((obj => obj.id === user.id));
                this.users[objIndex] = user;
            },
            deleteFromTable(obj) {
                this.array.splice(this.array.indexOf(obj), 1);
                Swal.fire(
                    'Видалено!',
                    'Користувач був видалений!.',
                    'success'
                )
            },


            onEdit(user) {
                axios.post(`/admin/users/get/${user.id}`)
                    .then(({data}) => this.showModal(this.text.updateModalTitle, data));
            },

            onDelete(user) {
                axios.delete(`/admin/users/delete/${user.id}`)
                    .then(() => this.loadData());
            },

            showModal(title, user = null) {
                this.$modal.show(
                    UserModal, //vue component
                    {   //component props
                        users: this.users,
                        roles: this.roles,
                        carriers: this.carriers,
                        title: title,
                        user: user,
                        loadData: this.loadData
                    }, {   //modal settings
                        height: 'auto',
                        draggable: false,
                    }, {
                        'push': this.pushToTable,
                        'edit': this.editInTable
                    }

                );
            },

            resetPassword(id) {
                Swal.fire({
                    title: 'Скинути пароль користувача?',
                    text: "Пароль користувача буде скинутий. Новий пароль буде надіслано користувачеві в SMS!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Скинути',
                    cancelButtonText: 'Відміна'
                }).then((result) => {
                    if (result.isConfirmed) {
                        axios.get(`/admin/users/${id}/send-new-password`)
                            .then(() => {
                                Swal.fire(
                                    'Пароль скинутий!',
                                    'Пароль був скинутий. Новий пароль надіслано користувачеві в SMS!.',
                                    'success'
                                )
                            })
                    }
                })
            }
        }
    }

</script>
