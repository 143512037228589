<template>
    <div class="card carriers-card">

        <card-header :text="this.text" @showModal="showModal"/>
        <!-- /.card-header -->

        <div class="card-body table-responsive">
            <table class="table table-bordered table-hover">
                <thead>
                <tr>
                    <th>{{ ('ID') }}</th>
                    <th>{{ ('Назва') }}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="carrier in carriers">
                    <td>{{carrier.id}}</td>
                    <td>{{carrier.name}}</td>
                    <td>
                        <div class="container-fluid">
                            <div class="row align-items-center">
                                <div class="col-6 offset-6">
                                    <ud-buttons
                                        :data="carrier"
                                        @onDelete="onDelete"
                                        @onEdit="onEdit"
                                    />
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <!-- /.card-body -->

    </div>
    <!-- /.card -->
</template>

<script>

    import CarrierModal from './modal';
    import CardHeader from '../../components/Table/CardHeader';
    import udButtons from "../../components/udButtons";
    import UserModal from "../users/modal";


    export default {
        components: {
            CarrierModal,
            'card-header': CardHeader,
            'ud-buttons': udButtons
        },
        data() {

            return {
                text: {
                    cardTitle: 'Перевізники',
                    createButtonText: 'Створити перевізника',
                    createModalTitle: 'Створення перевізника',
                    updateModalTitle: 'Редагування перевізника',
                },
                carriers: [],
            }

        },

        created() {
            this.loadData();
        },

        methods: {
            loadData() {
                axios.post('/admin/carriers').then(({data}) => this.carriers = data.carriers.reverse());
            },

            deleteFromList(obj) {
                Swal.fire(
                    'Вилучено!',
                    'Розклад було видалено!.',
                    'success'
                )
            },

            onEdit(carrier) {
                axios.get(`/admin/carriers/${carrier.id}`)
                    .then(({data}) =>{
                        console.log(data);
                            this.showModal(this.text.updateModalTitle, data.carrier)

                        }
                    );
            },

            onDelete(carrier) {
                axios.delete(`/admin/carriers/${carrier.id}/delete/`)
                    .then(() => this.loadData());
            },

            pushToTable(event) {
                this.carriers.push(event.carrier);
            },
            editInTable(carrier) {
                let objIndex = this.carriers.findIndex((obj => obj.id === carrier.id));
                this.carriers[objIndex] = carrier;
            },

            deleteFromTable(obj) {
                this.array.splice(this.array.indexOf(obj), 1);
                Swal.fire(
                    'Вилучено!',
                    'Користувач був вилучений!.',
                    'success'
                )
            },

            showModal(title, carrier = null) {
                this.$modal.show(
                    CarrierModal, //vue component
                    {   //component props
                        carriers: this.carriers,
                        title: title,
                        carrier: carrier,
                        loadData: this.loadData
                    }, {   //modal settings
                        height: 'auto',
                        draggable: false,
                    }, {
                        'push': this.pushToTable,
                        'edit': this.editInTable
                    }

                );
            },


        },
    }

</script>
