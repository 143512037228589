<template>
    <div class="modal-content">
        <div class="modal-header">
            <h4 class="modal-title">{{ ('Змінити точку маршруту') }}</h4>
            <button @click="$emit('close')" aria-label="Close" class="close" type="button">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <!-- Название точки маршрута -->
            <div class="form-group">
                <label class="form-label">{{ ('Назва точки') }}</label>
                <multiselect
                    v-model="routePoint.point"
                    :options="locations"
                    placeholder="Пункт відправки"
                    @search-change="searchLocations"
                    :internal-search="false"
                    :allow-empty="false"
                    :max-height="165"
                    label="location_start">
                    <template slot="singleLabel" slot-scope="props">
                                        <span class="option__desc">
                                            <span class="option__title" v-html="props.option.full_name"></span>
                                        </span>
                    </template>

                    <template slot="option" slot-scope="props">
                        <div class="option__desc">
                            <span class="option__title" v-html="props.option.full_name"></span>
                        </div>
                    </template>
                </multiselect>
            </div>

            <div class="form-group">
                <label class="form-label">{{ ('Адреса') }}</label>
                <input type="text" class="form-control" v-model="routePoint.address">
            </div>

            <!--Время прибытия-->
            <div class="row arrival-datetime d-flex flex-row flex-wrap justify-content-between">
<!--                TODO не может быть дата точки, отличаться от маршрута-->
<!--                <div class="chose-date_block col-md-7">-->
<!--                    <label class="form-label">{{ ('Дата прибытия') }}</label>-->
<!--                    <b-form-datepicker-->
<!--                        v-model="arrivalAt.date"-->
<!--                        placeholder="Выберите дату"-->
<!--                        locale="uk"-->
<!--                    ></b-form-datepicker>-->
<!--                </div>-->
                <div class="time col-md-5">
                    <label class="form-label">{{ ('Час прибуття') }}</label>
                    <input class="form-control"
                           type="time"
                           v-model="departureAt.time"
                    >
                </div>

            </div>
            <!-- .arrival-datetime -->
            <hr>

            <!--Время отправления-->
            <div class="row departure-datetime d-flex flex-row flex-wrap justify-content-between">

<!--                <div class="chose-date_block col-md-7">-->
<!--                    <label class="form-label">{{ ('Дата отправления') }}</label>-->
<!--                    <b-form-datepicker-->
<!--                        v-model="departureAt.date"-->
<!--                        placeholder="Выберите дату"-->
<!--                        locale="uk"-->
<!--                    ></b-form-datepicker>-->
<!--                </div>-->

                <div class="time col-md-5">
                    <label class="form-label">{{ ('Час відправлення') }}</label>
                    <input class="form-control"
                           type="time"
                           v-model="arrivalAt.time"
                    >
                </div>

            </div>
            <!-- .departure-datetime -->
            <hr>
        </div>
        <div class="modal-footer">
            <button @click="$emit('close')" class="btn btn-default" type="button">
                {{ ('Закрити') }}
            </button>
            <button @click="changeRoutePoint" class="btn btn-primary" type="button">
                {{ ('Змінити') }}
            </button>
        </div>
    </div>
</template>

<script>
    import Multiselect from 'vue-multiselect'

    export default {

        props: ['routePoints','selectedPoint', 'selectedIndex', 'callback'],

        components: {
            Multiselect
        },

        data() {
            return {
                routePoint: {
                    point: {
                        id: null,
                        name: ''
                    },
                    name: '',
                    arrival_at: '',
                    departure_at: '',
                    address: '',
                },
                departureAt: {
                    date: '',
                    time: ''
                },
                arrivalAt: {
                    date: '',
                    time: ''
                },
                locations: [],
            }
        },

        mounted() {
            if(this.selectedPoint){
                const dateTimeString = this.selectedPoint.departure_at;
                const dateTime = new Date(dateTimeString);

                const hours = dateTime.getHours();
                const minutes = dateTime.getMinutes();

                const formattedTime = `${hours}:${minutes < 10 ? '0' : ''}${minutes}`;
                this.departureAt.time = formattedTime;

                const dateTimeString2 = this.selectedPoint.arrival_at;
                const dateTime2 = new Date(dateTimeString2);

                const hours2 = dateTime2.getHours();
                const minutes2 = dateTime2.getMinutes();

                const formattedTime2 = `${hours2}:${minutes2 < 10 ? '0' : ''}${minutes2}`;
                this.arrivalAt.time = formattedTime2;

                this.routePoint.address = this.selectedPoint.address;
                this.routePoint.point.id = this.selectedPoint.point.id;
                this.routePoint.point = this.selectedPoint.point;
                this.routePoint.point.full_name = this.selectedPoint.point.name;
            }
        },

        methods: {
            changeRoutePoint() {
                this.routePoint.arrival_at = this.arrivalAt.time;
                this.routePoint.departure_at = this.departureAt.time;
                this.routePoint.point.address = this.routePoint.address;
                this.callback({ index: this.selectedIndex, routePoint: this.routePoint});
                this.$emit('close');
            },

            searchLocations(query) {
                if(query.length > 1){
                    this.fetchLocations(query);
                }
            },

            fetchLocations(query) {
                axios.get('/api/locations/?query=' + query)
                    .then(({data}) => {
                        this.locations = data.locations;
                    });
            },
        }
    }
</script>

<style scoped>

</style>
