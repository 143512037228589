export default {
    state: () => ({
        list: null,
    }),

    getters: {
        ALL_BUSES: state => state.list,
    },

    mutations: {
        SET_ALL_BUSES:(state,data) => {
            state.list = data;
        }
    },

    actions: {
        /**
         *
         * @param commit
         * @param params
         * @returns {Promise<void>}
         * @constructor
         */
        async GET_ALL_BUSES({ commit }, params) {
            await axios({ url: '/admin/buses/types', method: 'POST'})
                .then(resp => {
                    if (resp) {
                        console.log(resp.data);
                        console.log(resp.data.DATA);
                        commit('SET_ALL_BUSES', resp.data.DATA);
                    }
                })
                .catch(err => {
                    console.log(err);
                })
        }
    }
};
