<template>
    <div class="modal-content modal-booking">
        <div class="modal-header">
            <h4 class="modal-title">{{ title }}</h4>
            <button @click="$emit('close')" aria-label="Close" class="close" type="button">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">

            <!-- Расписание -->
            <div role="tablist">

                <b-card no-body class="mb-1">
                    <b-card-header header-tag="header" class="p-1" role="tab">
                        <!--Header schedule info-->
                        <div class="d-flex flex-row justify-content-between align-content-stretch flex-wrap p-2">
                            <div class="d-flex flex-column flex-nowrap justify-content-around mr-6">
                                <h4 class="font-weight-bold">{{schedule.location_start_name}} -
                                    {{schedule.location_end_name}}</h4>
                                <h6>{{schedule.bus_numeric}}</h6>
                            </div>

                            <div class="d-flex flex-column flex-nowrap justify-content-around mr-6">
                                <h6 class="font-weight-bold">Відправлення</h6>
                                <p class="date mr-2">{{ schedule.departure_at | moment("DD.MM.YYYY HH:mm") }}</p>
                            </div>

                            <div class="d-flex flex-column flex-nowrap justify-content-around mr-6">
                                <h6 class="font-weight-bold">Прибуття</h6>
                                <p class="date mr-2">{{ schedule.arrival_at | moment("DD.MM.YYYY HH:mm") }}</p>
                            </div>
                        </div>
                    </b-card-header>

                </b-card>

            </div>


            <form @keydown="form.errors.clear()" class="form-horizontal">
                <div class="card-body">
                    <div class="card-body_wrapp">
                    <div class="form-group w-full">
                        <b-form-datepicker
                            :date-disabled-fn="dateDisabled"
                            v-model="form.date"
                            :start-weekday="1"
                            placeholder="Виберіть дату"
                            locale="uk"
                            @context="changedDateSchedule()"
                        ></b-form-datepicker>
                    </div>

                    <!-- Номер телефона клиента -->
                    <div class="form-group">
                        <input class="form-control"
                               @focusout="getClient(client.number)"
                               placeholder="Номер телефону"
                               type="text"
                               v-model="client.number"
                               v-mask="'+38 (099) 999-99-99'"
                        >
                        <span class="invalid-feedback d-block"
                              role="alert"
                              v-if="form.errors.has('client.number')"
                              v-text="form.errors.get('client.number')"
                        />
                    </div>

                    <!-- Ф.И.О. -->
                    <div class="form-group">
                        <input class="form-control"
                               placeholder="ПІБ"
                               type="text"
                               v-model="client.fullName"
                        >
                        <span class="invalid-feedback d-block"
                              role="alert"
                              v-if="form.errors.has('fullName')"
                              v-text="form.errors.get('fullName')"
                        />
                    </div>

                    <!-- Комментарий -->
                    <div class="form-group">
                        <textarea class="form-control"
                                  placeholder="Коментар Водію"
                                  v-model="form.comment"
                        ></textarea>
                        <span class="invalid-feedback d-block"
                              role="alert"
                              v-if="form.errors.has('name')"
                              v-text="form.errors.get('name')"
                        />
                    </div>

                        <!-- Комментарий Диспетчера -->
                        <div class="form-group">
                        <textarea class="form-control"
                                  placeholder="Коментар Блокування"
                                  v-model="form.dispatcher_comment"
                        ></textarea>
                            <span class="invalid-feedback d-block"
                                  role="alert"
                                  v-if="form.errors.has('name')"
                                  v-text="form.errors.get('name')"
                            />
                        </div>
                    </div>


                    <!--Пункт отправления / Пункт прибытия -->
                    <div class="d-flex flex-row flex-wrap justify-content-between"
                         @focusout="getPrice"
                    >
                        <div class="form-group w-50 pr-1">
                            <label class="form-label">{{ ('Пункт відправки') }}</label>
                            <model-list-select
                                :list="sortedStartLocations"
                                v-model="location_start_id"
                                option-value="id"
                                option-text="name"
                                :custom-text="locationStartText"
                                placeholder="Виберіть пункт відправлення"
                                @change="resetSeatIndex"
                            ></model-list-select>
                        </div>
                        <div class="form-group w-50 pl-1">
                            <label class="form-label">{{ ('Пункт прибуття') }}</label>
                            <model-list-select
                                :list="sortedEndLocations"
                                v-model="location_end_id"
                                option-value="id"
                                option-text="name"
                                :custom-text="locationEndText"
                                placeholder="Виберіть пункт прибуття"
                                @change="resetSeatIndex"
                            ></model-list-select>
                        </div>
                    </div>

                    <div class="modal-info-wrapp">
                        <!-- Резерв-->
                        <b-form-checkbox v-model="form.isReserve" value="1" unchecked-value="0">Резерв</b-form-checkbox>

                        <!-- Цена -->
                        <div class="form-group">
                            <label class="form-label">{{ ('Вартість') }}:</label>
                            <h5>{{ isNaN(route.price/100) ? 0 : route.price/100 }}</h5>
                        </div>
                    </div>

                    <!-- Карта автобуса -->
                    <label class="form-label bus-container-label">{{ ('Вибір місця в автобусі') }}</label>
                    <bus-generator
                        :key="busGenKey"
                        :size="false"
                        :multiply="true"
                        v-bind:places="seats"
                        v-bind:schema="schedule.bus_schema"
                        v-bind:form="form"
                    ></bus-generator>
                </div>
                <div class="bus-seats-alert" v-if="alertSeats">
                    Оберіть місце в автобусі
                </div>
            </form>
        </div>

        <!--Create button-->
        <div v-permission="'orders'" class="modal-footer" v-if="! isArchive">
            <div class="modal-pdf">
                <!-- Ведомость -->
                <label class="form-label">{{ ('Відомість') }}:</label>
                <div>
                    <a class="btn btn-app btn-app_icon"
                    :href="`/admin/schedules/generate-pdf/${this.schedule.id}/${this.$moment(this.form.date).format('DD.MM.YYYY')}`"
                    target="_blank"
                    >
                        <i class="fas fa-clipboard-list "></i>
                    </a>
                </div>
            </div>
            <button
                class="btn exit-btn btn-secondary" type="button"
                @click="$emit('close')">
                {{ ('Відміна') }}
            </button>
            <button v-if="!update" class="btn submit-btn btn-secondary" type="button"
                    @click="create">
                {{ ('Забронювати') }}
            </button>
            <button v-if="update" class="btn submit-btn btn-secondary" type="button"
                    @click="updateOrder">
                {{ ('Оновити') }}
            </button>
        </div>
    </div>
</template>

<script>
    import {ModelListSelect} from 'vue-search-select';
    import BusGenerator from '../busgen';

    export default {
        name: "orderModal",
        props: ['schedule', 'locations', 'dataSeats', 'title', 'refreshTable',
                'update', 'clientInfo', 'updateSchedule', 'isArchive'],

        components: {
            'model-list-select': ModelListSelect,
            'bus-generator': BusGenerator,
        },

        data() {
            return {
                form: new Form({
                    schedule_id: this.schedule.id,
                    date: this.schedule.departure_at,
                    route_id: '',
                    client_id: '',
                    // todo: seat_index int -> array
                    seat_index: [],
                    comment: '',
                    dispatcher_comment: '',
                    isReserve: 0,
                    price: 0,
                }),
                alertSeats: false,
                location_start_id: '',
                location_end_id: '',
                route: [],
                client: {
                    fullName: '',
                    number: '',
                },
                busGenKey: 0,
                seats: this.dataSeats
            }
        },

        created() {
            // if (this.clientNumber != null) {
            //     this.getClient(this.client.number);
            // }

            // this.location_end_id = this.schedule.location_end_name;
            // this.location_start_id = this.schedule.location_start_name;
            // console.log(this.location_end_id, this.location_start_id);
        },

        watch: {
            'form.seat_index': function () {
                if(this.form.seat_index >= 1){
                    this.alertSeats = false;
                }
            },
            'form.isReserve': function () {
                if(this.form.isReserve === '1'){
                    this.alertSeats = false;
                }
            }
        },

        mounted() {
            if (! this.update) {
                this.location_end_id = this.locations.endLocation.id;
                this.location_start_id = this.locations.startLocation.id;

                return;
            }

            this.location_end_id = this.clientInfo.route.location_end_id;
            this.location_start_id = this.clientInfo.route.location_start_id;
            this.client = this.clientInfo.client;
            this.client.number = this.clientInfo.client.number.replace(/[^0-9]/g, '').slice(3);
            this.form.route_id = this.clientInfo.route_id;
            this.form.client_id = this.clientInfo.client_id;
            this.form.seat_index.push(this.clientInfo.seat_index);
            this.form.comment = this.clientInfo.comment;
            this.form.dispatcher_comment = this.clientInfo.dispatcher_comment;
            this.form.isReserve = this.clientInfo.is_reserve;
            this.route = this.clientInfo.route;
            this.form.price = this.route.price / 100;
        },

        computed: {
            sortedStartLocations() {
                return this.locations.startLocations.slice().sort((a, b) => {
                    return new Date(a.departure_at) - new Date(b.departure_at);
                });
            },
            sortedEndLocations() {
                return this.locations.endLocations.slice().sort((a, b) => {
                    return new Date(a.departure_at) - new Date(b.departure_at);
                });
            }
        },

        methods: {
            resetSeatIndex() {
                this.form.seat_index = [];
            },
            locationStartText(location) {
                return `${location.name} ${this.$moment(location.departure_at).format('HH:mm')}`;
            },
            locationEndText(location) {
                return `${location.name} ${this.$moment(location.arrival_at).format('HH:mm')}`;
            },
            async changedDateSchedule() {
                //TODO да и вообще этот метод как то не очень, ну дальше будет видно!

                //TODO это конечно не то что нужно, или как минимум вынести с методаё
                if (this.location_end_id == this.location_start_id) {
                    return false;
                }

                let routeDates = await axios.post('/admin/routes/get-by-locations',
                                            {'schedule_id': this.schedule.id,
                                                  'location_start_id': this.location_start_id,
                                                   'location_end_id': this.location_end_id})
                                            .then(({data}) => {
                                                this.route = data.DATA;
                                                this.form.route_id = data.DATA.id;
                                                this.form.price = data.DATA.price / 100;

                                                return {departureAt: data.DATA.departure_at, arrivalAt: data.DATA.arrival_at, routeId: data.DATA.id};
                                            });

                //TODO это очень стращный момент, но хз как поступить, с учетом текущей базы данных.
                let departureAt = this.$moment(this.form.date).format('YYYY-MM-DD') + ' ' + this.$moment(routeDates.departureAt).format('HH:mm');
                let arrivalAt = this.$moment(this.form.date).format('YYYY-MM-DD') + ' ' + this.$moment(routeDates.arrivalAt).format('HH:mm');

                await this.changeDate(departureAt, arrivalAt, routeDates.routeId);
            },

            dateDisabled(ymd, date) {
                // Disable weekends (Sunday = `0`, Saturday = `6`) and
                // disable days that fall on the 13th of the month
                const weekday = date.getDay()
                const day = date.getDate()
                // Return `true` if the date should be disabled

                return weekday === (!this.schedule.every_monday ? 1 : null)
                    || weekday === (!this.schedule.every_tuesday ? 2 : null)
                    || weekday === (!this.schedule.every_wednesday ? 3 : null)
                    || weekday === (!this.schedule.every_thursday ? 4 : null)
                    || weekday === (!this.schedule.every_friday ? 5 : null)
                    || weekday === (!this.schedule.every_saturday ? 6 : null)
                    || weekday === (!this.schedule.every_sunday ? 0 : null)
            },

            async getPrice() {
                if(!this.location_start_id || !this.location_end_id) {
                    return false;
                }

                if (this.location_end_id == this.location_start_id) {
                    return false;
                }

                let routeDates = await axios.post('/admin/routes/get-by-locations', {
                    'schedule_id': this.schedule.id,
                    'location_start_id': this.location_start_id,
                    'location_end_id': this.location_end_id
                }).then(({data}) => {

                    this.route = data.DATA;
                    this.form.route_id = data.DATA.id;
                    this.form.price = data.DATA.price / 100;

                    return {departureAt: data.DATA.departure_at, arrivalAt: data.DATA.arrival_at, routeId: data.DATA.id};
                });

                //TODO это очень стращный момент, но хз как поступить, с учетом текущей базы данных.
                let departureAt = this.$moment(this.form.date).format('YYYY-MM-DD') + ' ' + this.$moment(routeDates.departureAt).format('HH:mm');
                let arrivalAt = this.$moment(this.form.date).format('YYYY-MM-DD') + ' ' + this.$moment(routeDates.arrivalAt).format('HH:mm');

                await this.changeDate(departureAt, arrivalAt, routeDates.routeId);
            },

            getClient(number) {
                if (number === '') {
                    return false;
                }

                axios.post(`/admin/clients/get-by-number/${number}`)
                     .then(({data}) => {
                        if (data.DATA){
                            this.client = data.DATA
                            //TODO это вообще не очень, нужно везде поменять переменный объекта client
                            this.client.name = data.DATA.fullName
                            this.form.client_id = data.DATA.id
                            this.client.number = this.client.number.replace(/[^0-9]/g, '').slice(3);

                            if (data.DATA.last_comment && data.DATA.last_comment.comment) {
                                this.form.comment = data.DATA.last_comment.comment;
                            }

                            if (data.DATA.last_dispatcher_comment && data.DATA.last_dispatcher_comment.dispatcher_comment) {
                                this.form.dispatcher_comment = data.DATA.last_dispatcher_comment.dispatcher_comment;
                            }
                        }
                     });
            },

            async changeDate(departureAt = null,  arrivalAt = null, routeId = null) {
                if (departureAt === null && arrivalAt === null) {
                    departureAt = this.schedule.departure_at;
                    arrivalAt = this.schedule.arrival_at;
                }

                await axios.post('/admin/routes/booked-seats', {
                    routeId: routeId,
                    departureAt: departureAt,
                    arrivalAt: arrivalAt,
                }).then(({data}) => {
                    this.seats = {
                        busy: data.bookedSeats,
                        chosen: [],
                        idle: ['В'], // недоступные места
                    }
                    this.busGenKey += 1;
                });
            },

            create() {
                if(this.form.seat_index < 1 && (!this.form.isReserve || this.form.isReserve === '0')){
                    this.alertSeats = true;
                    return
                }
                this.locations.startLocations.forEach((item, index, array) => {
                    if (item.id == this.location_start_id) {
                        this.form.date = this.$moment(this.form.date).format('YYYY-MM-DD') + ' ' + this.$moment(item.departure_at).format('HH:mm');

                        return false;
                    }
                });
                let updatedClient = {};
                let clientUp = this.client;
                Object.keys(clientUp).forEach(function(key) {
                    updatedClient[key] = clientUp[key];
                });
                updatedClient.number = this.client.number;

                axios.post('/admin/orders/store', {
                        'order': this.form,
                        'client': updatedClient
                    })
                    .then(({data}) => {
                        this.$emit('close');
                        this.refreshTable();

                        Swal.fire('Збережено!');
                    })
                    .catch(error => {
                        if (error.response && error.response.status === 409) {
                            Swal.fire('Помилка', 'Цей білет уже заброньовано.', 'error');

                            this.refreshTable();
                            this.changedDateSchedule();
                        } else {
                            // Обработка других ошибок
                            Swal.fire('Помилка', 'Сталася помилка під час збереження замовлення.', 'error');
                        }
                    });
            },

            updateOrder() {
                this.locations.startLocations.forEach((item, index, array) => {
                    if (item.id == this.location_start_id) {
                        this.form.date = this.$moment(this.form.date).format('YYYY-MM-DD') + ' ' + this.$moment(item.departure_at).format('HH:mm');

                        return false;
                    }
                });

                if (this.client.number.length < 11) {
                    this.client.number = '380'+this.client.number;
                }

                axios.post(`/admin/orders/update/${this.clientInfo.id}`, {
                           'order': this.form,
                           'client': this.client
                    })
                    .then(({data}) => {
                        if (data.DATA) {
                            this.refreshTable();
                            this.updateSchedule();
                            this.$emit('close');
                            Swal.fire('Оновлено!');
                        }
                    })
            },

            generatePdf() {
                axios({
                        url: `/admin/schedules/generate-pdf/${this.schedule.id}}`,
                        method: 'GET',
                }).then((response) => {
                    window.open(response);
                });
            }
        }
    }
</script>

<style scoped>

</style>
