<template>
    <div class="content">
        <div class="container-fluid">
            <div class="card orders-card col-lg-12">

                <div class="card-header">
                    <div class="d-flex flex-row justify-content-between align-content-center">
                        <div></div>
                        <h3 class="card-title text-dark">{{ ('Список замовлень') }}</h3>
                                                <div class="сard-tools d-flex flex-row justify-content-end">
                                                    <div class="form-group date-form-group">
<!--                                                        <label class="form-label">{{ ('Дата відправлення') }}</label>-->
                                                        <b-form-datepicker
                                                            v-model="date"
                                                            placeholder="Виберіть дату"
                                                            :start-weekday="1"
                                                            locale="uk"
                                                        ></b-form-datepicker>
                                                    </div>
                                                    <b-button class="submit-btn" @click="find">Знайти</b-button>
                                                </div>
                    </div>
                </div>
                <!-- /.card-header -->

                <div class="card-body table-responsive orders-table">
                    <table class="table table-bordered table-hover dataTable">
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>{{ ('Номер квитка') }}</th>
                            <th>{{ ('Ім’я') }}</th>
                            <th>{{ ('Номер телефона') }}</th>
                            <th>{{ ('Маршрут') }}</th>
                            <th>{{ ('Дата поїздки') }}</th>
                            <th>{{ ('Час поїздки') }}</th>
                            <th>{{ ('Місце') }}</th>
                            <th>{{ ('Вартість') }}</th>
                            <th>{{ ('Статус замовлення') }}</th>
                            <th>{{ ('Дата створення') }}</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="order in orders">
                            <td>{{ order.id }}</td>
                            <td>{{ order.ticket_number }}</td>
                            <td>{{ order.client.fullName }}</td>
                            <td>{{ order.client.number }}</td>
                            <td>{{ order.route.location_start.name + ' - ' + order.route.location_end.name }}</td>
                            <td>{{ new Date(order.date).toLocaleDateString() }}</td>
                            <td>{{ new Date(order.date).toLocaleTimeString() }}</td>
                            <td>{{ order.seat_index }}</td>
                            <td>{{ order.price }} грн</td>
                            <td>
                                <div v-if="order.invoice != undefined && order.invoice != null">
                                    <span class="badge badge-info" v-if="order.invoice.status == 0">{{ ('Оформлено') }}</span>
                                    <span class="badge badge-success" v-if="order.invoice.status == 1">{{ ('Сплачено') }}</span>
                                    <span class="badge badge-danger" v-if="order.invoice.status == 2">{{ ('Помилка') }}</span>
                                </div>
                                <div v-else>
                                    <span class="badge badge-success" v-if="order.checked_in">{{ ('Сплачено') }}</span>
                                    <span class="badge badge-secondary" v-else-if="order.is_reserve">{{ ('Заброньовано') }}</span>
                                    <span class="badge badge-info" v-else>{{ ('Оформлено') }}</span>
                                </div>
                            </td>
                            <td>{{ new Date(order.created_at).toLocaleString() }}</td>
                            <td class="delete-td">
                                <a @click="onDelete(order)" class="btn btn-app btn-app_icon">
                                    <i class="fas fa-trash"></i>
                                </a>
                            </td>
                        </tr>
                        </tbody>
                    </table>

                    <div class="pagination">
                        <button @click="prevPage" :disabled="currentPage === 1">Попередня</button>

                        <button v-if="1 < currentPage - 2" @click="goToPage(1)">1</button>
                        <span v-if="currentPage - 3 > 1">...</span>

                        <button
                            v-for="page in visiblePages"
                            :key="page"
                            @click="goToPage(page)"
                            :class="{ active: currentPage === page }"
                        >
                            {{ page }}
                        </button>

                        <span v-if="currentPage + 3 < totalPages">...</span>
                        <button v-if="totalPages > currentPage + 2" @click="goToPage(totalPages)">{{ totalPages }}</button>

                        <button @click="nextPage" :disabled="currentPage === totalPages">Наступна</button>
                    </div>

                    <p>Страница {{ currentPage }} из {{ totalPages }}</p>
                </div>
                <!-- /.card-body -->

            </div>
            <!-- /.card -->
        </div>
        <!-- /.container-fluid -->
    </div>
</template>

<script>

    export default {
        data() {

            return {
                orders: [],
                client_name: '',
                client_number: '',
                date: '',
                currentPage: 1,
                itemsPerPage: 20,
                totalItems: 0,
            }
        },

        created() {
            this.fetchOrders();
        },

        computed: {
            totalPages() {
                return Math.ceil(this.totalItems / this.itemsPerPage);
            },
            visiblePages() {
                let pages = [];

                for (let i = this.currentPage - 2; i <= this.currentPage + 2; i++) {
                    if (i > 0 && i <= this.totalPages) {
                        pages.push(i);
                    }
                }

                return pages;
            }
        },

        methods: {
            fetchOrders () {
                axios
                    .post('/admin/orders/from-site', {
                        page: this.currentPage,
                        perPage: this.itemsPerPage,
                        // fullName: this.client_name,
                        // phone: this.client_number,
                        date: this.date
                    })
                    .then((response) => {
                        console.log(response)
                        this.orders = response.data.DATA.orders.data;
                        this.totalItems = response.data.DATA.orders.total;
                    });
            },
            // showModal(title, client = null) {
            //     this.$modal.show(
            //         modal, //vue component
            //         {   //component props
            //             client: client,
            //             title: title,
            //         }, {   //modal settings
            //             height: 'auto',
            //             width: '70%',
            //             draggable: true,
            //             adaptive: true,
            //             scrollable: true,
            //             clickToClose: false,
            //         }
            //     );
            // },

            onEdit(order) {
                // this.showModal('Информация о заказе', order);
            },
            onDelete(order) {
                Swal.fire({
                    title: 'Ви дійсно хочете видалити поточного пасажира ?',
                    input: 'text',
                    text: 'Уточніть обов’язково причину видалення!',
                    inputAttributes: {
                        autocapitalize: 'off'
                    },
                    showCancelButton: true,
                    confirmButtonText: 'Видалити',
                    cancelButtonText: 'Відміна',
                    showLoaderOnConfirm: true,
                    preConfirm: (comment) => {
                        if (comment.length <= 4) {
                            Swal.showValidationMessage(
                                `Заповніть будь-яке поле (більше 4 символів)`
                            );

                            return false;
                        }

                        axios({url: `/admin/orders/delete/${order.id}`, method: 'DELETE', data: {comment: comment}})
                            .then(({data}) => {
                                if (data) {
                                    this.getClients();
                                }
                            })
                            .catch(err => {
                                Swal.fire({
                                    icon: 'error',
                                    title: 'Oops...',
                                    text: 'Ви не можете видалити цього користувача',
                                })
                            });

                    },
                    allowOutsideClick: () => {
                        !Swal.isLoading()
                    }
                }).then((result) => {
                    if (result.value) {
                        Swal.fire({
                            title: 'Ви вдало видалили пасажира',
                        });
                    }
                });
            },
            //TODO о май гад, что это за две очень не похожие функции
            searchByName(name) {
                // axios.post(`/admin/clients`, {fullName: name})
                //     .then(({data}) => this.clients = data);
            },

            searchByNumber(number) {
                // axios.post(`/admin/clients`, {phone: number})
                //     .then(({data}) => this.clients = data);
            },
            find() {
                this.currentPage = 1;
                this.fetchOrders();
            },

            prevPage() {
                if (this.currentPage > 1) {
                    this.currentPage--;
                    this.fetchOrders();
                }
            },
            nextPage() {
                if (this.currentPage < this.totalPages) {
                    this.currentPage++;
                    this.fetchOrders();
                }
            },
            goToPage(page) {
                this.currentPage = page;
                this.fetchOrders();
            },
        }
    }
</script>

<style scoped>
.orders-table .table{
    min-width: 100%;
}
.orders-table tr th{
    font-size: 13px;
}
.orders-table tr td{
    font-size: 13px;
}
.orders-table .delete-td {
    position: relative;
    padding: 0;
    width: 58px;
}
.orders-table .delete-td a.btn.btn-app{
    padding: 0;
    font-size: 14px;
    margin: 0;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    min-width: auto;
}
.orders-table .delete-td a.btn.btn-app i{
    font-size: 15px;
}
</style>
