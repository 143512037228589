<template>
    <div class="modal-content modal-schedule">
        <div class="modal-header">
            <h4 class="modal-title">{{ title }}</h4>
            <button @click="$emit('close')" aria-label="Close" class="close" type="button">
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M13.7071 1.70711C14.0976 1.31658 14.0976 0.683417 13.7071 0.292893C13.3166 -0.0976311 12.6834 -0.0976311 12.2929 0.292893L7 5.58579L1.70711 0.292893C1.31658 -0.0976311 0.683417 -0.0976311 0.292893 0.292893C-0.0976311 0.683417 -0.0976311 1.31658 0.292893 1.70711L5.58579 7L0.292893 12.2929C-0.0976311 12.6834 -0.0976311 13.3166 0.292893 13.7071C0.683417 14.0976 1.31658 14.0976 1.70711 13.7071L7 8.41421L12.2929 13.7071C12.6834 14.0976 13.3166 14.0976 13.7071 13.7071C14.0976 13.3166 14.0976 12.6834 13.7071 12.2929L8.41421 7L13.7071 1.70711Z"
                          fill="#393D5C" fill-opacity="0.5"/>
                </svg>
            </button>
        </div>
        <div class="modal-body">

            <!-- Расписание -->
            <div no-body class="card-info">
                <div class="card-info-list">

                    <!--Название и автобус-->
                    <div class="details">
                        <h4>
                            {{ schedule.location_start_name }} - {{ schedule.location_end_name }}
                        </h4>
                        <h6>{{ schedule.bus_numeric }}</h6>
                    </div>

                    <!--Отправление-->
                    <div class="dates">
                        <h6>Відправлення</h6>
                        <p>{{ schedule.departure_at | moment("DD.MM.YYYY HH:mm") }}</p>
                    </div>

                    <!--Прибытие-->
                    <div class="dates">
                        <h6>Прибуття</h6>
                        <p>{{ schedule.arrival_at | moment("DD.MM.YYYY HH:mm") }}</p>
                    </div>

                    <div class="modal-info-pdf" v-if="trip.id!==null">
                        <!-- Ведомость -->
                        <label class="form-label">{{ ('Відомість') }}</label>
                        <div>
                            <a class="btn btn-app btn-app_icon"
                               :href="`/admin/schedules/generate-pdf/${this.schedule.id}/${this.$moment(this.trip.date).format('DD.MM.YYYY')}`"
                               target="_blank"
                            >
                                <i class="fas fa-clipboard-list "></i>
                            </a>
                        </div>
                    </div>

                    <button type="button" class="btn-save" @click="save()">
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M12 6H2V2H12V6ZM9 16C8.20435 16 7.44129 15.6839 6.87868 15.1213C6.31607 14.5587 6 13.7956 6 13C6 12.2044 6.31607 11.4413 6.87868 10.8787C7.44129 10.3161 8.20435 10 9 10C9.79565 10 10.5587 10.3161 11.1213 10.8787C11.6839 11.4413 12 12.2044 12 13C12 13.7956 11.6839 14.5587 11.1213 15.1213C10.5587 15.6839 9.79565 16 9 16ZM14 0H2C0.89 0 0 0.9 0 2V16C0 16.5304 0.210714 17.0391 0.585786 17.4142C0.960859 17.7893 1.46957 18 2 18H16C16.5304 18 17.0391 17.7893 17.4142 17.4142C17.7893 17.0391 18 16.5304 18 16V4L14 0Z"
                                fill="white"/>
                        </svg>
                        Зберегти
                    </button>
                </div>
            </div>

            <!--Вкладки-->
            <div :class="[{'driver-type': dryverType}, 'card-content']" no-body>
                <b-tabs card>
                    <!--Список-->
                    <b-tab no-body title="Список" class="table-users-list">
                        <div class="table-users">
                            <div class="table-users__list heading">
                                <div class="item">
                                    {{ ('Місце пасажира') }}
                                </div>
                                <div class="item">
                                    {{ ('Список пасажирів') }}
                                </div>
                                <div class="item">
                                    {{ ('Номер телефона') }}
                                </div>
                                <div class="item">
                                    {{ ('Пункт') }}
                                </div>
                                <div class="item">
                                    {{ ('Сума') }}
                                </div>
                                <div class="item">
                                    {{ ('Спосіб оплати') }}
                                </div>
                                <div class="item">
                                    {{ ('Статус оплати') }}
                                </div>
                                <div class="item">
                                    {{ ('Коментар') }}
                                </div>
                                <div class="item" v-permission="'orders'" v-if="!isArchive">

                                </div>
                            </div>
                            <div class="table-users__list" v-for="(place, index) in booked" :key="place.id">
                                 <div class="item" style="justify-content: center;">
                                    {{ place.seat_index }}
                                </div>
                                <div class="item">
                                    {{ place.client.fullName }}
                                </div>
                                <div class="item">
                                    {{ place.client.number }}
                                </div>
                                <div class="item">
                                    {{ place.route.location_start.name }} - {{ place.route.location_end.name }}
                                </div>
                                <div class="item">
                                    {{ place.price }}
                                </div>
                                <div class="item">
                                    {{ place.payType == 'online' ? 'Онлайн' : 'Готівка' }}
                                </div>
                                <div class="item" v-if="place.invoice == null">
                                    {{ place.checked_in ? 'Сплачено' : ' Оформлено' }}
                                </div>
                                <div class="item" v-else>
                                    {{ place.invoice.status == 1 ? 'Сплачено' : 'Очікується' }}
                                </div>
                                <div class="item">
                                    {{ showComments(place) }}
                                </div>
                                <div class="item users-nav" v-permission="'orders'" v-if="!isArchive">
                                    <a @click="editOrder(place.order_id)" class="edit">
                                        <i class="fas fa-edit"></i>
                                    </a>
                                    <a @click="deleteOrder(place.order_id, place.client.fullName, place.seat_index)" class="delete">
                                        <i class="fas fa-trash"></i>
                                    </a>
                                    <a @click="downloadTicket(place.order_id)" class="edit">
                                        <i class="fas fa-ticket-alt"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </b-tab>

                    <!--В автобусе-->
                    <b-tab no-body title="В автобусі">
                        <div class="card-header pb-0">
                            <button type="button" class="btn" :class="{'btn-primary': this.disableMode, 'btn-secondary': !this.disableMode}" @click="toggleDisableMode">
                                <i class="fas fa-ban"></i>
                                {{ this.disableMode ? 'Підтвердити бронювання' : 'Відмінити бронювання' }}
                            </button>

                            <button type="button" class="btn btn-primary" @click="addAdditionalPassengersCost" v-if="trip.id">
                                <i class="fas fa-money-bill"></i>
                                {{ 'Додаткова сума поїздки' }}
                            </button>
                        </div>

                        <bus-generator
                            ref="busGeneratorRef"
                            :size="true"
                            :sale="false"
                            :classes="'pt-0'"
                            @selectedBusSlot="checkIn"
                            v-bind:places="seats"
                            v-bind:type="schedule.type_id"
                            v-bind:schema="schedule.bus_schema"
                        ></bus-generator>
                    </b-tab>

                    <!--Резерв-->
                    <b-tab v-if="!dryverType" no-body title="Резерв">
                        <div class="table-users">
                            <div class="table-users__list heading">
                                <div class="item" style="justify-content: center;">
                                    {{ ('Місце пасажира') }}
                                </div>
                                <div class="item">
                                    {{ ('Список пасажирів') }}
                                </div>
                                <div class="item">
                                    {{ ('Номер телефона') }}
                                </div>
                                <div class="item" v-permission="'orders'" v-if="!isArchive">

                                </div>
                            </div>
                            <div class="table-users__list" v-for="(place, index) in reserved" :key="place.id">
                                <div class="item">
                                    {{ place.seat_index }}
                                </div>
                                <div class="item">
                                    {{ place.client.fullName }}
                                </div>
                                <div class="item">
                                    {{ place.client.number }}
                                </div>
                                <div class="item users-nav" v-permission="'orders'" v-if="!isArchive">
                                    <a @click="editOrder(place.order_id)" class="edit">
                                        <i class="fas fa-edit"></i>
                                    </a>
                                    <a @click="deleteOrder(place.order_id, place.client.fullName, place.seat_index)" class="delete">
                                        <i class="fas fa-trash"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </b-tab>

                    <b-tab v-if="!dryverType" no-body title="Чек лист" class="table-check-list">
                        <div class="table-checklist">
                            <div class="table-checklist__list heading">
                                <div class="item">
                                    {{ ('Місце пасажира') }}
                                </div>
                                <div class="item">
                                    {{ ('ПІБ') }}
                                </div>
                                <div class="item">
                                    {{ ('Номер телефона') }}
                                </div>
                                <div class="item">
                                    {{ ('Пункт') }}
                                </div>
                                <div class="item">
                                    {{ ('Сума') }}
                                </div>
                                <div class="item">
                                    {{ ('Спосіб оплати') }}
                                </div>
                                <div class="item">
                                    {{ ('Статус оплати') }}
                                </div>
                                <div class="item">
                                    {{ ('Позначка') }}
                                </div>
                                <div class="item">
                                    {{ ('Коментар') }}
                                </div>
                            </div>
                            <div class="table-checklist__list" v-for="(place, index) in booked" :key="place.id">
                                <div class="item" style="justify-content: center;">
                                    {{ place.seat_index }}
                                </div>
                                <div class="item">
                                    {{ place.client.fullName }}
                                </div>
                                <div class="item">
                                    {{ place.client.number }}
                                </div>
                                <div class="item">
                                    {{ place.route.location_start.name }} - {{ place.route.location_end.name }}
                                </div>
                                <div class="item">
                                    {{ place.price }}
                                </div>
                                <div class="item">
                                    {{ place.payType == 'online' ? 'Онлайн' : 'Готівка' }}
                                </div>
                                <div class="item" v-if="place.invoice == null">
                                    {{ place.checked_in ? 'Сплачено' : ' Оформлено' }}
                                </div>
                                <div class="item" v-else>
                                    {{ place.invoice.status == 1 ? 'Сплачено' : 'Очікується' }}
                                </div>
                                <div class="item">
                                    <div class="checkbox-container">
                                        <input type="checkbox" autocomplete="off" v-model="place.checked_in" :id="place.order_id">
                                        <label :for="place.order_id"></label>
                                    </div>
                                </div>
                                <div class="item">
                                    {{ showComments(place) }}
                                </div>
                            </div>
                        </div>
                    </b-tab>
                    <b-tab no-body title="Доп">
                        <div class="table-additionally trip-additional" v-if="trip.id !== null">
                            <div class="table-additionally__list">
                                <div class="passengers">
                                    <div class="text"> Додат. пасажир:</div>
                                    <div class="check-list">
                                        <template v-for="i in 8">
                                            <input type="radio" v-model="trip.additional_passengers" :value="i" :id="'additional_passengers_'+i">
                                            <label :for="'additional_passengers_'+i" class="mb-0">{{ i }}</label>
                                        </template>
                                    </div>
                                </div>
                                <div class="price">
                                    <label for="additional_passengers_cost">Вартість:</label>
                                    <input id="additional_passengers_cost" type="number" min="0" class="form-control" v-model="trip.additional_passengers_cost">
                                </div>
                            </div>

                            <div class="table-additionally__list">
                                <div class="passengers">
                                    <div class="text">Додат. багаж:</div>
                                    <div class="check-list">
                                        <template v-for="i in 8">
                                            <input type="radio" v-model="trip.additional_baggage" :value="i" :id="'additional_baggage'+i">
                                            <label :for="'additional_baggage'+i">{{ i }}</label>
                                        </template>
                                    </div>
                                </div>
                                <div class="price">
                                    <label for="additional_passengers_cost">Вартість:</label>
                                    <input id="additional_baggage_cost" type="number" min="0" class="form-control" v-model="trip.additional_baggage_cost">
                                </div>
                            </div>
                        </div>
                    </b-tab>
                    <b-tab v-if="!dryverType" no-body title="Автобус" v-permission="'orders'">
                        <div class="table-additionally trip-additional">
                            <!--Автобус-->
                            <div class="form-group">
                                <label class="form-label">{{ ('Автобус') }}</label>
                                <model-list-select
                                    :list="buses"
                                    v-model="trip.bus_id"
                                    option-value="id"
                                    option-text="numeric"
                                    placeholder="Виберіть автобус"
                                ></model-list-select>
                            </div>
                        </div>
                    </b-tab>
                    <b-tab v-if="!dryverType" no-body title="Водій" v-permission="'orders'">
                        <div class="table-additionally trip-additional">
                            <!--Водитель-->
                            <div class="form-group">
                                <label class="form-label">{{ ('Водій') }}</label>
                                <model-list-select
                                    :list="drivers"
                                    v-model="trip.driver_id"
                                    option-value="id"
                                    option-text="name"
                                    placeholder="Виберіть водія"
                                ></model-list-select>
                            </div>
                        </div>
                    </b-tab>

                </b-tabs>
            </div>
        </div>
    </div>

</template>

<script>
import udButtons from "../../components/udButtons";
import BusGenerator from "../busgen";
import OrderModal from "./modal";
import {ModelListSelect} from 'vue-search-select';
import 'vue-search-select/dist/VueSearchSelect.css'
import Table from "./table.vue";

export default {
        name: "ScheduleModal",
        props: ['schedule', 'locations', 'seats', 'title', 'showModal', 'refreshTable', 'isArchive'],

        components: {
            OrderModal,
            'ud-buttons': udButtons,
            'bus-generator': BusGenerator,
            ModelListSelect
        },

        data() {
            return {
                drivers: [],
                buses: [],
                booked: [],
                indexBooked: 0,
                indexReserve: 0,
                reserved: [],
                trip: {
                    id: null,
                    driver_id: null,
                    bus_id: null,
                },
                dryverType: false,
                disableMode: false,
                temporaryPlaces: [],
            }
        },

        computed: {
            allPlaces() {
                return this.temporaryPlaces.concat(this.booked).concat(this.reserved);
            }
        },

        mounted() {
            this.getClients();
            this.loadDrivers();
            this.loadBuses();
            this.getTrip();
        },

        async created() {
            const { data: roles } = await axios.get('/admin/roles');
            this.$laravel.setRoles(roles);
            this.checkDriverRole(roles);
        },

        methods: {
            checkDriverRole(roles){
                if(roles[0] === 'driver'){
                    this.dryverType = true;
                }else{
                    this.dryverType = false;
                }
            },
            loadDrivers() {
                axios.post('/admin/users/get-by-role/driver', {
                    carrier_id: this.schedule.carrier_id
                }).then(({data}) => this.drivers = data);
            },
            loadBuses() {
                axios.post('/admin/buses').then(({data}) => this.buses = data.DATA);
            },

            findBus(id) {
                return this.buses.find(bus => bus.id === id)
            },

            getClients() {
                axios.post(`/admin/orders/${this.schedule.id}/clients`, {
                    departureAt: this.schedule.departure_at,
                    arrivalAt: this.schedule.arrival_at
                })
                    .then(({data}) => {
                        this.booked = data.DATA.clients;
                        this.reserved = data.DATA.clientsReserve;
                    });
            },

            getTrip() {
                axios.get(`/admin/trips?schedule_id=${this.schedule.id}&date=${this.schedule.departure_at}`)
                    .then(({data}) => {
                        this.trip = data.DATA.trip;
                    })
            },

            deleteOrder(orderId, client, place) {
                Swal.fire({
                    title: 'Ви дійсно хочете видалити ' + client + ' з місцем ' + place + '?',
                    input: 'text',
                    text: 'Уточніть обов’язково причину видалення!',
                    inputAttributes: {
                        autocapitalize: 'off'
                    },
                    showCancelButton: true,
                    confirmButtonText: 'Видалити',
                    cancelButtonText: 'Відміна',
                    showLoaderOnConfirm: true,
                    preConfirm: (comment) => {
                        if (comment.length <= 4) {
                            Swal.showValidationMessage(
                                `Заповніть будь-яке поле (більше 4 символів)`
                            );

                            return false;
                        }

                        axios({url: `/admin/orders/delete/${orderId}`, method: 'DELETE', data: {comment: comment}})
                            .then(({data}) => {
                                if (data) {
                                    this.getClients();
                                }
                            })
                            .catch(err => {
                                Swal.fire({
                                    icon: 'error',
                                    title: 'Oops...',
                                    text: 'Ви не можете видалити цього користувача',
                                })
                            });

                    },
                    allowOutsideClick: () => {
                        !Swal.isLoading()
                    }
                }).then((result) => {
                    if (result.value) {
                        Swal.fire({
                            title: 'Ви вдало видалили пасажира',
                        });
                    }
                });
            },
            async editOrder(orderId) {
                let client = await axios.post(`/admin/orders/${orderId}`)
                    .then(({data}) => {
                        if (data.DATA) {
                            return data.DATA;
                        }
                    });


                Promise.all([
                    this.getScheduleLocations(this.schedule),
                    this.getBookedSeats(this.schedule)
                ]).then(([routeLocationsResponse, bookedSeatsResponse]) => {
                    this.$modal.show(
                        OrderModal, //vue component
                        {   //component props
                            schedule: this.schedule,
                            locations: routeLocationsResponse.data.locations,
                            seats: {
                                busy: bookedSeatsResponse.data.bookedSeats, // занятые места
                                chosen: bookedSeatsResponse.data.checkedSeats,
                                purchased: bookedSeatsResponse.data.purchasedSeats,
                                idle: ['В'], // недоступные места
                            },
                            title: 'Список пасажирів на рейс',
                            refreshTable: this.refreshTable,
                            showModal: this.showModal,
                            update: true,
                            clientInfo: client,
                            updateSchedule: this.getClients,

                        }, {   //modal settings
                            height: 'auto',
                            draggable: false,
                            adaptive: true,
                            scrollable: true,
                            clickToClose: false,
                        }
                    );
                });

            },

            async downloadTicket(orderId) {
                try {
                    const response = await axios.post(`/admin/orders/${orderId}/download`, {}, {
                        responseType: 'blob'
                    });

                    const blob = new Blob([response.data], { type: 'application/pdf' });

                    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                        window.navigator.msSaveOrOpenBlob(blob, 'ticket.pdf');
                    } else {
                        const url = window.URL.createObjectURL(blob);
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'ticket.pdf');
                        link.click();
                        window.URL.revokeObjectURL(url);
                    }
                } catch (error) {
                    console.error('Помилка при завантаженні квитка:', error);
                }
            },

            save() {
                Promise.all([this.saveCheckIn(), this.saveTrip()]).then(() => {
                    Swal.fire('Оновлено!');
                    this.reloadModalState();

                    if (this.trip.bus_id) {
                        const bus = this.findBus(this.trip.bus_id);

                        this.schedule.bus_numeric = bus.numeric;
                        this.schedule.bus_schema = bus.bus_schema;
                    }
                })
            },

            saveCheckIn() {
                if (this.allPlaces.length > 0) {
                    return axios.patch('/admin/orders/update-orders-check-in', {
                        orders: this.allPlaces
                    })
                }
            },

            saveTrip() {
                return axios.patch(`/admin/trips/${this.trip.id}/update`, this.trip)
            },

            getBookedSeats(schedule) {
                return axios.post('/admin/schedules/booked-seats', {
                    schedule_id: schedule.id,
                    departure_at: schedule.departure_at,
                    arrival_at: schedule.arrival_at,
                })
            },

            getScheduleLocations(schedule) {
                return axios.post('/admin/schedules/locations', {
                    schedule_id: schedule.id,
                })
            },
            toggleDisableMode() {
                this.disableMode = !this.disableMode;
            },
            checkIn(item) {
                let target = this.allPlaces.find(place => place.seat_index === item.n);

                if (this.disableMode) {
                    if (target && target.hasOwnProperty('id')) {
                        if (BusGenerator.methods.isBusy(item) || BusGenerator.methods.isPurchased(item)) {
                            BusGenerator.methods.setDisabled(item);
                            target.for_deleting = true;
                        } else {
                            if (target.invoice_id === null || !target.invoice || target.invoice.status !== 1) {
                                BusGenerator.methods.setBusy(item);
                            } else {
                                BusGenerator.methods.setPurchased(item)
                            }

                            delete target.for_deleting;
                        }
                    }
                } else {
                    if (!target) {
                        target = {
                            seat_index: item.n,
                            for_creating: true,
                            is_reserve: false,
                            trip_id: this.trip.id,
                            schedule_id: this.schedule.id,
                            route_id: this.schedule.main_route_id,
                            date: this.schedule.departure_at,
                            price: this.trip.additional_passengers_cost,
                        };
                        this.temporaryPlaces.push(target);
                    }

                    if (!BusGenerator.methods.isChosen(item)) {
                        BusGenerator.methods.setChosen(item);
                        target.checked_in = true;
                        delete target.for_deleting;
                    } else if (BusGenerator.methods.isChosen(item)) {
                        if (target.hasOwnProperty('for_creating')) {
                            BusGenerator.methods.setDefault(item);
                            this.temporaryPlaces.splice(this.temporaryPlaces.map(p => p.seat_index).indexOf(item.n), 1);
                        } else {
                            if (target.invoice_id === null || !target.invoice || target.invoice.status !== 1) {
                                BusGenerator.methods.setBusy(item);
                            } else {
                                BusGenerator.methods.setPurchased(item)
                            }
                        }

                        target.checked_in = false;
                    }
                }

                BusGenerator.methods.refreshItem(item);
            },
            reloadBusGenerator() {
                this.getClients();

                this.getBookedSeats(this.schedule).then(response => {
                    this.$refs.busGeneratorRef.hidden = true;
                    this.$refs.busGeneratorRef.buildScheme({
                        busy: response.data.bookedSeats,
                        chosen: response.data.checkedSeats,
                        purchased: response.data.purchasedSeats,
                        idle: ['В'],
                    });
                });
            },
            reloadModalState() {
                this.temporaryPlaces = [];
                this.disableMode = false;
                this.reloadBusGenerator();
            },
            addAdditionalPassengersCost() {
                Swal.fire({
                    title: 'Введіть додаткову суму поїздки',
                    input: 'number',
                    inputValue: this.trip.additional_passengers_cost,
                    showCancelButton: true,
                    confirmButtonText: 'Зберегти',
                    cancelButtonText: 'Відміна',
                }).then((result) => {
                    if (result.value !== undefined && result.value !== this.trip.additional_passengers_cost) {
                        Swal.fire({title: 'Оновлення'});
                        Swal.showLoading();
                        axios
                            .patch(`/admin/trips/${this.trip.id}/update`, {
                                'id': this.trip.id,
                                'additional_passengers_cost': result.value,
                            })
                            .then(({data}) => {
                                // this.$emit('close');
                                this.trip.additional_passengers_cost = data.DATA.trip.additional_passengers_cost;

                                Swal.fire('Збережено!');
                            })
                            .catch(function(error) {
                                if (error.response) {
                                    Swal.fire({
                                        icon: "error",
                                        title: "Помилка, спробуйте пізніше",
                                    });
                                }
                            });
                    }
                });
            },
            showComments(place) {
                let comments = "";

                if(place.comment) comments += place.comment;

                if (place.comment && place.client_comment) {
                    comments += ', ';
                }

                if (place.client_comment) comments += place.client_comment;

                if (place.dispatcher_comment) comments += ", " + place.dispatcher_comment;

                return comments
            }
        }
    }
</script>

<style scoped>

</style>
