<template>
    <div class="content">
        <div class="container-fluid">
            <div class="card clients-card col-lg-12">

                <div class="card-header">
                    <div class="d-flex flex-row justify-content-between align-content-center">
                        <div></div>
                        <h3 class="card-title text-dark">{{ ('Список клієнтів') }}</h3>
                        <div class="сard-tools d-flex flex-row justify-content-end">
                            <b-input-group prepend="П.І.Б.">
                                <b-form-input @keyup.enter.native="searchByName(client_name)" v-model="client_name"></b-form-input>
                                <b-input-group-append>
                                    <b-button
                                        @click="searchByName(client_name)"
                                        variant="info">
                                        <i class="fas fa-search"></i>
                                    </b-button>
                                </b-input-group-append>
                            </b-input-group>

                            <b-input-group prepend="Номер">
                                <b-form-input @keyup.enter.native="searchByNumber(client_number)" v-mask="'+38 (099) 999-99-99'" v-model="client_number"></b-form-input>
                                <b-input-group-append>
                                    <b-button
                                        variant="info"
                                        @click="searchByNumber(client_number)">
                                        <i class="fas fa-search"></i>
                                    </b-button>
                                </b-input-group-append>
                            </b-input-group>
                        </div>
                    </div>
                </div>
                <!-- /.card-header -->

                <div class="card-body table-responsive">
                    <table class="table table-bordered table-hover dataTable">
                        <thead>
                        <tr>
                            <th>{{ ('Кількість поїздок') }}</th>
                            <th>{{ ('Ім’я') }}</th>
                            <th>{{ ('Номер') }}</th>
                            <th>{{ ('Останній коментар') }}</th>
                            <th>{{ ('Коментар блокування') }}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr @click="onEdit(client)" v-for="client in clients">
                            <td>{{client.orders_count}}</td>
                            <td>{{client.fullName}}</td>
                            <td>{{client.number}}</td>
                            <td>{{client.last_comment == null ? '' : client.last_comment.comment }}</td>
                            <td>{{client.last_dispatcher_comment == null ? '' : client.last_dispatcher_comment.dispatcher_comment }}</td>
<!--                            <td>-->
<!--                                <ud-buttons-->
<!--                                    v-bind:id="client"-->
<!--                                    @onDelete="onDelete(client)"-->
<!--                                    @onEdit="onEdit(client)"-->
<!--                                />-->
<!--                            </td>-->
                        </tr>
                        </tbody>
                    </table>

                    <div class="pagination">
                        <button @click="prevPage" :disabled="currentPage === 1">Попередня</button>

                        <button v-if="1 < currentPage - 2" @click="goToPage(1)">1</button>
                        <span v-if="currentPage - 3 > 1">...</span>

                        <button
                            v-for="page in visiblePages"
                            :key="page"
                            @click="goToPage(page)"
                            :class="{ active: currentPage === page }"
                        >
                            {{ page }}
                        </button>

                        <span v-if="currentPage + 3 < totalPages">...</span>
                        <button v-if="totalPages > currentPage + 2" @click="goToPage(totalPages)">{{ totalPages }}</button>

                        <button @click="nextPage" :disabled="currentPage === totalPages">Наступна</button>
                    </div>

                    <p>Страница {{ currentPage }} из {{ totalPages }}</p>
                </div>
                <!-- /.card-body -->

            </div>
            <!-- /.card -->
        </div>
        <!-- /.container-fluid -->
    </div>
</template>

<script>
    import udButtonsComponent from "../../components/udButtons";
    import modal from "./client-info-modal";


    export default {
        name: "clients",

        components: {
            'ud-buttons': udButtonsComponent
        },
        data() {

            return {
                clients: [],
                client_name: '',
                client_number: '',
                currentPage: 1,
                itemsPerPage: 20,
                totalItems: 0,
            }


        },

        created() {

            this.fetchClients();
        },

        computed: {
            totalPages() {
                return Math.ceil(this.totalItems / this.itemsPerPage);
            },
            visiblePages() {
                let pages = [];

                for (let i = this.currentPage - 2; i <= this.currentPage + 2; i++) {
                    if (i > 0 && i <= this.totalPages) {
                        pages.push(i);
                    }
                }

                return pages;
            }
        },

        methods: {
            fetchClients() {
                axios
                    .post('/admin/clients', {
                        page: this.currentPage,
                        perPage: this.itemsPerPage,
                        fullName: this.client_name,
                        phone: this.client_number
                    })
                    .then((response) => {
                        this.clients = response.data.data
                        this.totalItems = response.data.total
                    });
            },
            showModal(title, client = null) {
                this.$modal.show(
                    modal, //vue component
                    {   //component props
                        client: client,
                        title: title,
                    }, {   //modal settings
                        height: 'auto',
                        width: '70%',
                        draggable: false,
                        adaptive: true,
                        scrollable: true,
                        clickToClose: false,
                    }

                );
            },

            onEdit(client) {
                this.showModal('Информация пассажира', client);
            },
            onDelete(client) {
            },
            searchByName(name) {
                this.client_name = name;
                this.currentPage = 1;
                this.fetchClients()
            },
            searchByNumber(number) {
               this.client_number = number.replace(/[^0-9]/g, '').slice(3);
               this.currentPage = 1;
                this.fetchClients();
            },

            prevPage() {
                if (this.currentPage > 1) {
                    this.currentPage--;
                    this.fetchClients();
                }
            },
            nextPage() {
                if (this.currentPage < this.totalPages) {
                    this.currentPage++;
                    this.fetchClients();
                }
            },
            goToPage(page) {
                this.currentPage = page;
                this.fetchClients();
            },
        }
    }
</script>

<style scoped>
.pagination button {
    margin: 0 5px;
    padding: 5px 10px;
    border: none;
    background-color: #ddd;
    cursor: pointer;
}

.pagination button.active {
    background-color: #42b983;
    color: white;
}

.pagination button:disabled {
    background-color: #eee;
    cursor: not-allowed;
}
</style>
