export const ROLES = ['bus_item-empty', 'bus_item-place'];
export const ROLES_BUTTONS = ['bus_button', 'bus_button-busy', 'bus_button-idle', 'bus_button-chosen', 'bus_button-disabled', 'bus_button-purchased'];
export const ROLES_NAMES = {
    default: 0,
    busy: 1,
    idle: 2,
    chosen: 3,
    disabled: 4,
    purchased: 5
};
