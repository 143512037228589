import Vue from "vue";

window.Vue = require('vue');

Vue.prototype.$dayList = [
    {name: 'Пн', column: 'every_monday'},
    {name: 'Вт', column: 'every_tuesday'},
    {name: 'Ср', column: 'every_wednesday'},
    {name: 'Чт', column: 'every_thursday'},
    {name: 'Пт', column: 'every_friday'},
    {name: 'Сб', column: 'every_saturday'},
    {name: 'Нд', column: 'every_sunday'},
];


