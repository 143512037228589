<template>
    <button class="btn btn-primary btn-lg"
            type="button"
            @click="$emit('showModal')">
        {{ text.createButtonText }}
    </button>
</template>

<script>
    export default {
        name: "CreateButton",
        props: ['text']
    }
</script>

<style scoped>

</style>
