<template>
    <div class="content">
        <div class="container-fluid">
            <div class="card activities-card col-lg-12">

                <div class="card-body table-responsive">

                    <div class="filters-container">
                        <div class="row d-flex flex-row flex-wrap">

                            <!--DATE RANGE-->
                            <div class="d-flex col-md-2">
                                <div class="form-group">
                                    <label class="form-label">{{ ('Дата') }}</label>
                                    <input class="form-control date_input" type="date"
                                        v-bind:placeholder="(new Date()).getDate()"
                                        v-model="filter.date"
                                    >
                                </div>
                            </div>

                            <div class="d-flex col-md-2">
                                <div class="form-group">
                                    <label class="form-label">{{ ('Кім змінено') }}</label>
                                    <input class="form-control" type="text"
                                        v-model="filter.causer"
                                    >
                                </div>
                            </div>

                            <div class="d-flex col-md-2">
                                <div class="form-group">
                                    <label class="form-label">{{ ('Тип змінного запису') }}</label>
                                    <select class="form-control" v-model="filter.subject_type">
                                        <option value="" selected>Не вибрано</option>
                                        <option value="client">Клієнт</option>
                                        <option value="order">Замовлення</option>
                                    </select>
                                </div>
                            </div>

                            <div class="d-flex col-md-2">
                                <div class="form-group">
                                    <label class="form-label">{{ ('Подія') }}</label>
                                    <select class="form-control" v-model="filter.description">
                                        <option value="" selected>Не вибрано</option>
                                        <option value="create">Створення</option>
                                        <option value="update">Оновлення</option>
                                        <option value="delete">Видалення</option>
                                    </select>
                                </div>
                            </div>
                            <div class="d-flex align-items-center col-md-4">
                                <div class="form-group">
                                    <button type="button" @click.prevent="filterActivities" class="btn submit-btn btn-secondary">Фільтрувати</button>
                                    <button type="button" @click.prevent="loadData" class="btn submit-btn btn-secondary">Скинути фільтр</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="table-wrapp">
                        <table class="table table-bordered table-hover dataTable">
                            <thead>
                            <tr>
                                <th>{{ ('Кім змінено') }}</th>
                                <th>{{ ('Змінний запис') }}</th>
                                <th>{{ ('Подія') }}</th>
                                <th>{{ ('Зміни') }}</th>
                                <th>{{ ('Дата') }}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="activity in activities">
                                <td>{{ activity.causer.name }}</td>
                                <td>{{ activity.subject_type.replace('App\\Models\\', '') + ' - id: ' + activity.subject_id }}</td>
                                <td>{{ activity.description }}</td>
                                <td>{{ 'До: ' + JSON.stringify(activity.properties.old) }}<br>
                                    {{ 'Після: ' + JSON.stringify(activity.properties.attributes) }}
                                </td>
                                <td>{{ getDate(activity) }}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <!-- /.card-body -->

            </div>
            <!-- /.card -->
        </div>
        <!-- /.container-fluid -->
    </div>
</template>

<script>

    export default {
        data() {
            return {
                activities: [],
                filter: {
                    date: '',
                    causer: '',
                    description: '',
                    subject_type: '',
                }
            }
        },

        created() {
            this.loadData();
        },

        methods: {
            loadData() {
                axios.post('/admin/activities').then(({data}) => {
                    this.activities = data.DATA.activities;
                })
            },

            getDate(activity) {
                return new Date(Date.parse(activity.created_at)).toLocaleString();
            },

            filterActivities(){
                axios.post('/admin/activities', this.filter).then(({data}) => {
                    this.activities = data.DATA.activities;
                })
            }
        }
    }
</script>