<template>
    <div class="content card sheet-card">
        <div class="card-header">
            <div class="d-flex flex-row justify-content-between align-content-center">
                <h3 class="card-title text-dark text-center w-100">{{ ('Відомість') }}</h3>
                <!--<div class="сard-tools">
                    <div class="input-group input-group-sm" style="width: 150px;">
                        <button class="btn btn-default" type="submit">
                            <i class="fas fa-calendar"></i>
                        </button>
                        <button class="btn btn-default" type="submit">
                            <i class="fas fa-search"></i>
                        </button>
                    </div>
                </div>-->
            </div>
        </div>
        <!-- /.card-header -->

        <div class="card-body">
            <div class="filters-container">
                <div class="row d-flex flex-row flex-wrap justify-content-around">

                    <!--DATE RANGE-->
                    <div class="dateRange d-flex justify-content-between col-md-3">
                        <div class="date-from w-50 pr-2">
                            <div class="form-group">
                                <label class="form-label">{{ ('Дата від') }}</label>
                                <input class="form-control date_input"
                                       @change="onFilter"
                                       v-bind:placeholder="(new Date()).getDate()"
                                       type="date"
                                       v-model="filter.date_from"
                                >
                                <span class="invalid-feedback d-block" role="alert" v-if="filter.errors.has('date_from')"
                                      v-text="filter.errors.get('date_from')"></span>
                            </div>
                        </div>
                        <div class="date-from w-50 pl-2">
                            <div class="form-group">
                                <label class="form-label">{{ ('Дата до') }}</label>
                                <input class="form-control date_input"
                                       @change="onFilter"
                                       v-bind:placeholder="(new Date()).getDate()"
                                       type="date"
                                       v-model="filter.date_to"
                                >
                                <span class="invalid-feedback d-block" role="alert" v-if="filter.errors.has('date_to')"
                                      v-text="filter.errors.get('date_to')"></span>
                            </div>
                        </div>
                    </div>

                    <!--ROUTE-->
                    <div class="route col-md-3">
                        <label class="form-label">{{ ('Маршрут') }}</label>
                        <input class="form-control"
                               @change="onFilter"
                               type="text"
                               v-model="filter.schedule_name"
                        >
                        <span class="invalid-feedback d-block" role="alert" v-if="filter.errors.has('schedule_name')"
                              v-text="filter.errors.get('schedule_name')"></span>
                    </div>

                    <!--BUS-->
                    <div class="form-group col-md-2" @focusout="onFilter">
                        <label class="form-label">{{ ('Автобус') }}</label>
                        <div class="d-flex flex-row justify-content-start align-items-stretch">
                            <model-list-select
                                :list="buses"
                                v-model="filter.bus_id"
                                option-value="id"
                                option-text="numeric"
                                placeholder="Виберіть автобус"
                            ></model-list-select>

                            <i class="fas fa-window-close"
                               @click="filter.bus_id = null"
                            ></i>
                        </div>
                        <span class="invalid-feedback d-block" role="alert" v-if="filter.errors.has('bus_id')"
                              v-text="filter.errors.get('bus_id')"></span>
                    </div>

                    <!--DRIVER-->
                    <div class="form-group col-md-2" @focusout="onFilter">
                        <label class="form-label">{{ ('Водій') }}</label>
                        <div class="d-flex flex-row justify-content-start align-items-stretch">
                            <model-list-select
                                :list="drivers"
                                v-model="filter.driver_id"
                                option-value="id"
                                option-text="name"
                                placeholder="Виберіть водія"
                                @focusout="onFilter"
                            ></model-list-select>

                            <i class="fas fa-window-close"
                                @click="filter.driver_id = null"
                            ></i>
                        </div>
                        <span class="invalid-feedback d-block" role="alert" v-if="filter.errors.has('time')"
                              v-text="filter.errors.get('time')"></span>
                    </div>

                    <!--DRIVER-->
                    <div class="form-group col-md-2" @focusout="onFilter">
                        <label class="form-label">{{ ('Перевізник') }}</label>
                        <div class="d-flex flex-row justify-content-start align-items-stretch">
                            <model-list-select
                                :list="carriers"
                                v-model="filter.carrier_id"
                                option-value="id"
                                option-text="name"
                                placeholder="Виберіть перевізника"
                                @focusout="onFilter"
                            ></model-list-select>

                            <i class="fas fa-window-close"
                               @click="filter.carrier_id = null"
                            ></i>
                        </div>
                        <span class="invalid-feedback d-block" role="alert" v-if="filter.errors.has('time')"
                              v-text="filter.errors.get('time')"></span>
                    </div>
                </div>
            </div>

            <div class="table-responsive mt-4">
                <table class="table table-bordered table-hover dataTable">
                    <thead>
                    <tr>
                        <th>{{ ('Місце') }}</th>
                        <th>{{ ('Пункт призначення') }}</th>
                        <th>{{ ('Ціна') }}</th>
                        <th>{{ ('Ім’я пасажира') }}</th>
                        <th>{{ ('Телефон') }}</th>
                        <th>{{ ('Дата') }}</th>
                        <th>{{ ('Дата створення') }}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="order in orders">
                        <td>{{order.seat_index}}</td>
                        <td v-if="order.route">{{ order.route.location_start.name }} - {{ order.route.location_end.name}}</td>
                        <td v-else>undefined - undefined</td>
                        <td>{{ isNaN(order.price) ? 0 : order.price }}</td>
                        <td>{{order.client.fullName}}</td>
                        <td>{{order.client.number}}</td>
                        <td>{{ order.date }}</td>
                        <td>{{ order.created_at }}</td>
                    </tr>
                    </tbody>
                </table>

                <div class="pagination">
                    <button @click="prevOrderPage" :disabled="filter.orderPage === 1">Попередня</button>

                    <button v-if="1 < filter.orderPage - 2" @click="goToOrderPage(1)">1</button>
                    <span v-if="filter.orderPage - 3 > 1">...</span>

                    <button
                        v-for="page in visibleOrderPages"
                        :key="page"
                        @click="goToOrderPage(page)"
                        :class="{ active: filter.orderPage === page }"
                        :disabled="filter.orderPage === page"
                    >
                        {{ page }}
                    </button>

                    <span v-if="filter.orderPage + 3 < orderTotalPages">...</span>
                    <button v-if="orderTotalPages > filter.orderPage + 2" @click="goToOrderPage(orderTotalPages)">{{ orderTotalPages }}</button>

                    <button @click="nextOrderPage" :disabled="filter.orderPage === orderTotalPages">Наступна</button>
                </div>

                <p>Сторінка {{ filter.orderPage }} из {{ orderTotalPages }}</p>
            </div>
        </div>

        <div class="table-responsive mt-4" v-if="trips">
            <table class="table table-bordered table-hover dataTable">
                <thead>
                <tr>
                    <th>{{ ('Пункт призначення') }}</th>
                    <th>{{ ('Доп. місця') }}</th>
                    <th>{{ ('Сума за доп. місця') }}</th>
                    <th>{{ ('Доп. багаж') }}</th>
                    <th>{{ ('Сума за доп. багаж') }}</th>
                    <th>{{ ('Дата') }}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="trip in trips">
                    <td v-if="trip.schedule.route">{{ trip.schedule.route.location_start.name }} - {{ trip.schedule.route.location_start.name }}</td>
                    <td v-else>undefined - undefined</td>
                    <td>{{trip.additional_passengers}}</td>
                    <td>{{trip.additional_passengers_cost}}</td>
                    <td>{{ trip.additional_baggage }}</td>
                    <td>{{ trip.additional_baggage_cost }}</td>
                    <td>{{ trip.date }}</td>
                </tr>
                </tbody>
            </table>

            <div class="pagination">
                <button @click="prevTripPage" :disabled="filter.tripPage === 1">Попередня</button>

                <button v-if="1 < filter.tripPage - 2" @click="goToTripPage(1)">1</button>
                <span v-if="filter.tripPage - 3 > 1">...</span>

                <button
                    v-for="page in visibleTripPages"
                    :key="page"
                    @click="goToTripPage(page)"
                    :class="{ active: filter.tripPage === page }"
                    :disabled="filter.tripPage === page"
                >
                    {{ page }}
                </button>

                <span v-if="filter.tripPage + 3 < tripTotalPages">...</span>
                <button v-if="tripTotalPages > filter.tripPage + 2" @click="goToTripPage(tripTotalPages)">{{ tripTotalPages }}</button>

                <button @click="nextTripPage" :disabled="filter.tripPage === tripTotalPages">Наступна</button>
            </div>

            <p>Сторінка {{ filter.tripPage }} из {{ tripTotalPages }}</p>
        </div>

        <div class="sheet-card__nav">
            <div class="col-2">
                <a :href="pdfLink" class="btn btn-primary">Завантажити PDF</a>
            </div>
            <div class="col-2 offset-8">
                Підсумок
                <div>Пасажирів: <b>{{ numberClients }}</b></div>
                <div>Дохід з квитків: <b>{{ allPrice }}</b></div>
                <div>Дохід з доп. місць: <b>{{ passengerAllPrice }}</b></div>
                <div>Дохід з доп. багажу: <b>{{ baggageAllPrice }}</b></div>
                <div>Загальний дохід: <b>{{ allPrice + passengerAllPrice + baggageAllPrice }}</b></div>
            </div>
        </div>
    </div>
</template>

<script>
    import { ModelListSelect } from 'vue-search-select';
    import 'vue-search-select/dist/VueSearchSelect.css'

    export default {
        components: {
            'model-list-select': ModelListSelect
        },

        data () {
            return {
                filter: new Form({
                    date_from: '',
                    date_to: '',
                    time: '',
                    schedule_name: '',
                    bus_id: '',
                    driver_id: '',
                    carrier_id: '',
                    orderPage: 1,
                    orderPerPage: 20,
                    tripPage: 1,
                    tripPerPage: 20
                }),
                week_days: [
                    {name: 'Пн', column: 'every_monday'},
                    {name: 'Вт', column: 'every_tuesday'},
                    {name: 'Ср', column: 'every_wednesday'},
                    {name: 'Чт', column: 'every_thursday'},
                    {name: 'Пт', column: 'every_friday'},
                    {name: 'Сб', column: 'every_saturday'},
                    {name: 'Нд', column: 'every_sunday'},
                ],
                route_points: '',
                orders: '',
                ordersTotal: '',
                tripsTotal: '',
                buses: [],
                drivers: [],
                carriers: [],
                allPrice: 0,
                numberClients: 0,
                passengerAllPrice: 0,
                baggageAllPrice: 0,
                trips: null,
            }
        },

        created() {
            this.onFilter();
            axios.post('/admin/buses').then(({data}) => this.buses = data.DATA);
            axios.post('/admin/users/get-by-role/driver').then(({data}) => this.drivers = data);
            this.loadCarriers();
        },

        computed: {
            pdfLink(){
                let queryString = Object.keys(this.filter).map((key) => {
                    if(key == 'errors' || key == 'originalData'){
                        return;
                    }

                    return encodeURIComponent(key) + '=' + encodeURIComponent(this.filter[key])
                }).join('&');

                return '/admin/orders/generate-pdf?'+queryString;
            },
            orderTotalPages() {
                return Math.ceil(this.ordersTotal / this.filter.orderPerPage)
            },
            visibleOrderPages() {
                let pages = [];

                for (let i = this.filter.orderPage - 2; i <= this.filter.orderPage + 2; i++) {
                    if (i > 0 && i <= this.orderTotalPages) {
                        pages.push(i);
                    }
                }

                return pages;
            },
            tripTotalPages() {
                return Math.ceil(this.tripsTotal / this.filter.tripPerPage)
            },
            visibleTripPages() {
                let pages = [];

                for (let i = this.filter.tripPage - 2; i <= this.filter.tripPage + 2; i++) {
                    if (i > 0 && i <= this.tripTotalPages) {
                        pages.push(i);
                    }
                }

                return pages;
            }
        },

        methods: {
            loadCarriers(){
                axios.post('/admin/carriers').then(({data}) => {
                    this.carriers = data.carriers
                    this.carriers.unshift({
                        id: 'unattached',
                        name: 'Без перевізника'
                    })
                    this.carriers.unshift({
                        id: '',
                        name: 'Усе'
                    })
                });
            },

            onFilter() {
                axios
                    .post('/admin/orders/filter', {
                        filter: this.filter
                    })
                    .then(({data}) => {
                        this.orders = data.DATA.orders.data
                        this.ordersTotal = data.DATA.orders.total
                        this.filter.orderPerPage = data.DATA.orders.per_page
                        this.filter.orderPage = data.DATA.orders.current_page
                        this.allPrice = data.DATA.allPrice
                        this.numberClients = data.DATA.numberClients
                        this.trips = data.DATA.trips.data
                        this.tripsTotal = data.DATA.trips.total
                        this.filter.tripPerPage = data.DATA.trips.per_page
                        this.filter.tripPage = data.DATA.trips.current_page
                        this.passengerAllPrice = data.DATA.passengerAllPrice
                        this.baggageAllPrice = data.DATA.baggageAllPrice
                    });
            },

            prevOrderPage() {
                if (this.filter.orderPage > 1) {
                    this.filter.orderPage--;
                    this.onFilter();
                }
            },
            nextOrderPage() {
                if (this.filter.orderPage < this.orderTotalPages) {
                    this.filter.orderPage++;
                    this.onFilter();
                }
            },
            goToOrderPage(page) {
                this.filter.orderPage = page;
                this.onFilter();
            },

            prevTripPage() {
                if (this.filter.tripPage > 1) {
                    this.filter.tripPage--;
                    this.onFilter();
                }
            },
            nextTripPage() {
                if (this.filter.tripPage < this.tripTotalPages) {
                    this.filter.tripPage++;
                    this.onFilter();
                }
            },
            goToTripPage(page) {
                this.filter.tripPage = page;
                this.onFilter();
            },
        }
    }
</script>

<style scoped>

</style>
