<template>
    <div class="modal-content">
        <div class="modal-header">
            <button @click="$emit('close')" aria-label="Close" class="close" type="button">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body" style="overflow-y: auto;">
            <form class="form-horizontal">
                <div class="card-body">

                    <!-- Главный маршрут -->
                    <div class="d-flex flex-row flex-wrap justify-content-between align-content-center">
                        <div class="form-group">
                            <label class="form-label">{{ ('Пункт відправки') }}</label>
                            <h4>{{ mainRoute.location_start.name }}</h4>
                        </div>
                        -------------------------
                        <div class="form-group">
                            <label class="form-label">{{ ('Пункт прибуття') }}</label>
                            <h4>{{ mainRoute.location_end.name }}</h4>
                        </div>
                    </div>
                    <hr>

                    <label class="form-label">{{ ('Точки маршруту') }}</label>
                    <div class="d-flex flex-column"
                        v-for="routes in routesArray">

                        <div class="d-flex flex-row flex-wrap justify-content-between"
                            v-for="route in routes"
                        >

                            <h5>{{ route.location_start_name }}</h5>
                            --------------------
                            <h5> {{ route.location_end_name }}</h5>

                            <div class="form-group">
<!--                                 v-if="route.location_start_id === mainRoute.location_start.id-->
<!--                                    && route.location_end_id === mainRoute.location_end.id"-->
<!--                            >-->
                                <!--                                       TODO изменить обработку цены на что то адекватное-->

                                <input class="form-control"
                                       placeholder="0.00"
                                       type="number"
                                       v-model="route.price"
                                >
                            </div>

                        </div>
                        <hr>

                    </div>


                </div>
            </form>
        </div>

        <div class="modal-footer">
            <button class="btn btn-default" type="button"
                    @click="$emit('close')">
                {{ ('Відміна') }}
            </button>
            <button v-if="!update" class="btn btn-info" type="button"
                    @click="create()">
                {{ ('Створити') }}
            </button>
            <button  v-if="update" class="btn btn-info" type="button"
                    @click="edit()">
                {{ ('Оновити') }}
            </button>
        </div>
    </div>
</template>

<script>

    export default {
        props: ['schedule', 'mainRoute', 'routesArray',
                'schedules', 'closeModal', 'loadData', 'update'],
        components: {
        },

        data() {

            return {

            }

        },

        mounted() {
            // console.log(this.schedule);
            // console.log(this.mainRoute);
            // console.log(this.routesArray);
        },
        filters: {
            parsePrice: function (value) {
                return isNaN(value / 100) ? 0 : value / 100;
            }
        },
        methods: {
            create() {
                console.log(this.routesArray);
                axios.post('/admin/schedules/create', {
                        schedule: this.schedule,
                        mainRoute: this.mainRoute,
                        routesArray: this.routesArray
                    })
                    .then(({data}) => {
                        this.loadData();
                        this.closeModal();
                        this.$emit('close');
                    });
            },

            edit() {
                this.schedule.routesArray = this.routesArray;
                axios
                    .post(`/admin/schedules/update/${this.schedule.id}`, this.schedule)
                    .then(({data}) => {
                        this.loadData();
                        this.closeModal();
                        this.$emit('close');
                    });
            },
            editInTable(user) {
                let objIndex = this.users.findIndex((obj => obj.id === user.id));
                this.users[objIndex] = user;
            },
        }
    }
</script>

<style scoped>

</style>
