<template>
    <div class="modal-content">
        <div class="modal-header">
            <h4 class="modal-title">{{ title }}</h4>
            <button @click="$emit('close')" aria-label="Close" class="close" type="button">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <form @keydown="form.errors.clear()" class="form-horizontal">
                <div class="card-body">

                    <!--Номер Т.С.-->
                    <div class="d-flex flex-row flex-wrap">
                        <label class="pr-1">{{ ('Номер автобуса') }}<span class="text-danger">*</span></label>
                        <div class="pl-1">
                            <input autofocus
                                   class="form-control"
                                   placeholder="Номер автобуса"
                                   required
                                   type="text"
                                   v-model="form.numeric"
                            >
                        </div>
                        <span v-if="$v.form.numeric.$error">
                            Номер автобуса має бути заповнений та довжиною 8 символів
                        </span>
                    </div>

                    <!--Тип автобуса-->
                    <div class="d-flex flex-row flex-wrap align-content-center">
                        <label class="pr-1">{{ ('Тип автобуса') }}<span class="text-danger">*</span></label>
                        <model-list-select
                            class="pl-1"
                            :list="types"
                            v-model="form.type_id"
                            option-value="id"
                            option-text="name"
                            placeholder="Виберіть тип автобуса"
                        ></model-list-select>
                    </div>

                    <!-- Схема автобуса -->
                    <bus-generator v-if="form.bus_type"
                        :size="false"
                        :sale="false"
                        v-bind:schema="form.bus_type.schema"
                    ></bus-generator>

                </div>
            </form>
        </div>
        <div class="modal-footer">
            <button class="btn btn-default" type="button"
                    @click="$emit('close')">
                {{ ('Закрити') }}
            </button>
            <button class="btn btn-info" type="button"
                    :disabled="form.errors.any()"
                    v-if="!bus"
                    @click="create">
                {{ ('Додати автобус') }}
            </button>
            <button class="btn btn-info" type="button"
                    :disabled="form.errors.any()"
                    v-else
                    @click="edit(bus.id)">
                {{ ('Редагувати автобус') }}
            </button>
        </div>
    </div>
</template>

<script>
    import {ModelListSelect} from 'vue-search-select';
    import {required, numeric, maxLength, minLength} from 'vuelidate/lib/validators';
    import BusGenerator from '../busgen';

    export default {
        props: ['array', 'title', 'bus', 'types', 'loadData'],

        components: {
            'model-list-select': ModelListSelect,
            'bus-generator': BusGenerator,
        },

        data() {
            return {
                form: new Form(),
            }

        },
        validations: {
            form: {
                numeric: {
                    required,
                    maxLength: maxLength(8),
                    minLength: minLength(8)
                },
                type_id: {
                    required,
                    numeric
                }
            }
        },

        created() {
            if (this.bus) {
                this.form = new Form(this.bus);
            }
        },

        methods: {
             create() {
                 axios.post('/admin/buses/create', this.form)
                    .then(({data}) => {
                        //TODO убрать перезагрузку страницы, обновить только данные компонента
                        if (data && data.STATUS == 200) {
                            this.loadData();
                            this.$emit('close');
                        }
                        // this.$emit('close');
                        // location.reload()
                    });
            },
            edit() {
                axios
                    .post(`/admin/buses/update/${this.form.id}`, this.form)
                    .then(({data}) => {
                        // this.array.edit(data.DATA);
                        // this.$emit('close');
                        this.loadData();
                        this.$emit('close');
                    });

            }
        }
    }
</script>

<style scoped>

</style>
