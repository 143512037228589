<template>
    <div class="card container card-booking">

<!--        <card-header :text="this.text"/>-->
        <!-- /.card-header -->

        <div class="card-body">
            <!--Search area-->
            <form @keydown="form.errors.clear()" class="form-horizontal booking-search">
                    <!--Пункт отправления / Пункт прибытия -->
                    <div class="booking-search__wrapp">
                        <div class="form-group from">
                            <label class="form-label">{{ ('Пункт відправки') }}</label>
                            <multiselect
                                v-model="form.location_start"
                                :options="locations"
                                placeholder="Виберіть пункт прибуття"
                                @search-change="searchLocations"
                                :internal-search="false"
                                :allow-empty="false"
                                :max-height="165"
                                label="location_start">
                                <template slot="singleLabel" slot-scope="props">
                                        <span class="option__desc">
                                            <span class="option__title" v-html="props.option.full_name"></span>
                                        </span>
                                </template>

                                <template slot="option" slot-scope="props">
                                    <div class="option__desc">
                                        <span class="option__title" v-html="props.option.full_name"></span>
                                    </div>
                                </template>
                            </multiselect>
                        </div>
                        <div class="form-group where">
                            <label class="form-label">{{ ('Пункт прибуття') }}</label>
                            <multiselect
                                v-model="form.location_end"
                                :options="locations"
                                placeholder="Виберіть пункт прибуття"
                                @search-change="searchLocations"
                                :internal-search="false"
                                :allow-empty="false"
                                :max-height="165"
                                label="location_end">
                                <template slot="singleLabel" slot-scope="props">
                                        <span class="option__desc">
                                            <span class="option__title" v-html="props.option.full_name"></span>
                                        </span>
                                </template>

                                <template slot="option" slot-scope="props">
                                    <div class="option__desc">
                                        <span class="option__title" v-html="props.option.full_name"></span>
                                    </div>
                                </template>
                            </multiselect>
                        </div>
                        <div class="form-group date-form-group">
                            <label class="form-label">{{ ('Дата відправлення') }}</label>
                            <b-form-datepicker
                                v-model="departureAt.date"
                                placeholder="Виберіть дату"
                                :start-weekday="1"
                                locale="uk"
                            ></b-form-datepicker>
                        </div>
                        <b-button class="submit-btn" @click="find">Знайти</b-button>
                    </div>
            </form>

            <div class="mt-5" role="tablist">
                <b-card class="route-card"  no-body
                    v-for="schedule in schedules"
                    :key="schedule.id">
                    <div class="route-card__heading">
                        {{schedule.name}}
                    </div>
                    <div v-if="dryverType" class="route-card-linkwrapp"  @click="showScheduleModal(schedule)" v-permission:any="'orders|checklist'"></div>
                    <b-card-header v-bind:class="{ is_archive: isArchive }" header-tag="header" role="tab">
                        <div class="route-card__wrapp">
                            <div class="route-card__info" @click="showModal(schedule)">
                                <div class="route">
                                    {{schedule.location_start_name}} - {{schedule.location_end_name}}
                                </div>
                                <div class="details">
                                    {{schedule.bus_numeric}}
                                </div>
                            </div>
                            <div class="route-card__details" @click="showModal(schedule)">
                                <div class="item">
                                    <div class="heading">
                                        Відправлення
                                    </div>
                                    <div class="content">
                                        <div class="days" v-html="customParseDate(schedule, schedule.departure_at)">
                                        </div>
                                    </div>
                                </div>
                                <div class="item" v-role:unless="'driver'">
                                    <div class="heading">
                                        Прибуття
                                    </div>
                                    <div class="content">
                                        <div class="days" v-html="customParseDate(schedule, schedule.arrival_at)">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="route-card__passengers" @click="showModal(schedule)">
                                <div class="item">
                                    <div class="heading">
                                        Кільк.пасажирів
                                    </div>
                                    <div class="content">
                                        {{ schedule.orders_count }}
                                    </div>
                                </div>
                                <div class="item">
                                    <div class="heading">
                                        Кільк.місць
                                    </div>
                                    <div class="content">
                                        {{ schedule.seats }}
                                    </div>
                                </div>
                            </div>
                            <div class="route-card__free" @click="showModal(schedule)">
                                <div class="item">
                                    <div class="heading">
                                        Вільно
                                    </div>
                                    <div class="content">
                                        {{ schedule.seats - schedule.orders_count }}
                                    </div>
                                </div>
                                <div class="item">
                                    <div class="heading">
                                        Резерв
                                    </div>
                                    <div class="content">
                                        {{ schedule.reserve_orders_count }}
                                    </div>
                                </div>
                            </div>
                            <div class="route-card__link" v-role:unless="'driver'">
                                <a @click="showScheduleModal(schedule)" v-permission:any="'orders|checklist'">
                                    <img src="/images/icons/info-icon.svg" alt="">
                                </a>
                            </div>
                        </div>
                    </b-card-header>
                </b-card>

            </div>

        </div>
    </div>
</template>

<script>
    import CardHeader from '../../components/Table/CardHeader';
    import OrderModal from "./modal";
    import ScheduleModal from './schedule-modal'
    import Multiselect from 'vue-multiselect'

    export default {
        props: ['title', 'schedule'],
        components: {
            ScheduleModal,
            'card-header': CardHeader,
            Multiselect
        },

        data() {
            return {
                checkDay: false,
                isArchive: true,
                dryverType: false,
                text: {
                    cardTitle: 'Бронювання',
                    createButtonText: 'Забронювати',
                    createModalTitle: 'Забронювати місце',
                    updateModalTitle: 'Редагувати бронь',
                },
                form: new Form({
                    location_start: '',
                    location_end: '',
                    departure_at: '',
                    week: {
                        //TODO не лучший вариант, изменить потом
                        every_monday: this.$moment().format('d') == 1,
                        every_tuesday: this.$moment().format('d') == 2,
                        every_wednesday: this.$moment().format('d') == 3,
                        every_thursday: this.$moment().format('d') == 4,
                        every_friday: this.$moment().format('d') == 5,
                        every_saturday: this.$moment().format('d') == 6,
                        every_sunday: this.$moment().format('d') == 7,
                    }
                }),
                departureAt: {
                    date: this.$moment().format(),
                    time: ''
                },
                locations: [],
                schedules: [],
                week: [],
            }

        },
        //TODO возможно из этого сделать ленивую загруз
        async created() {
            const { data: permissions } = await axios.get('/admin/permissions');
            const { data: roles } = await axios.get('/admin/roles');
            this.$laravel.setPermissions(permissions);
            this.$laravel.setRoles(roles);


            this.find();

            this.getPopularLocations();
            this.checkDriverRole(roles);

            const schedule = sessionStorage.getItem("schedule");
            if (schedule) {
                let scheduleObject = JSON.parse(schedule);
                if (! this.$moment(scheduleObject.departure_at).isBefore(this.$moment(), 'day')) {
                    this.isArchive = false;
                }

                this.showScheduleModal(scheduleObject);

                sessionStorage.removeItem("schedule");
            }
        },
        //TODO код дублируется.Всё нужно сделать иначе) Но будет это позже, когда будет рефакторится данный момент
        computed: {
            classDatePicker: function () {
                //TODO это не то, что нужно. Вынести отдельно проверку
                this.checkDay = !!+(!!+this.form.week.every_monday || !!+this.form.week.every_tuesday || !!+this.form.week.every_wednesday || !!+this.form.week.every_thursday || !!+this.form.week.every_friday || !!+this.form.week.every_saturday || !!+this.form.week.every_sunday);

                if(this.checkDay) {
                    this.departureAt.date = null;
                    this.form.departure_at = null;
                }

                return {
                    'disabled-picker': this.checkDay,
                }
            }
        },

        methods: {
            formatDate(date) {
                let result;
                let d = format(new Date(date).getDate())
                let m = format(new Date(date).getMonth() + 1)
                let y = format(new Date(date).getFullYear())
                let h = format(new Date(date).getHours())
                let c = format(new Date(date).getMinutes())

                result = `${d}/${m}/${y}, ${h}:${c}`;

                function format(s){
                    if(+s < 10) s = '0' + s;
                    return `${s}`;
                }

                return result;
            },

            customParseDate(schedule, dateTime) {
                if(!!+schedule.every_monday || !!+schedule.every_tuesday || !!+schedule.every_wednesday
                    || !!+schedule.every_thursday || !!+schedule.every_friday || !!+schedule.every_saturday
                    || !!+schedule.every_sunday) {

                    let result = '';

                    this.$dayList.forEach(function(item, index) {
                        if(!!+schedule[item.column]) {
                            result +=  item.name + ', '
                        }
                    });

                    return `${result}<span class="date_ros">${this.$moment(dateTime).format('HH:mm')}</span>`;
                } else {
                    return `<span class="date_ros">${this.$moment(dateTime).format('DD.MM.YYYY HH:mm')}</span>`;
                }
            },

            find() {
                if (this.departureAt.date !== null) {
                    // this.form.departure_at = `${this.departureAt.date}T${this.departureAt.time}`;
                    this.form.departure_at = this.$moment(this.departureAt.date).format('YYYY-MM-DD');

                    // if (this.departureAt.time !== '') {
                    //     this.form.departure_at += ' ' + this.$moment.utc(this.departureAt.time).format('HH:mm')
                    // }
                }


                axios
                    .post('/admin/schedules/find', {form: this.form})
                    .then(({data}) => {
                        this.schedules = data.DATA.schedules;
                        this.isArchive = data.DATA.isArchive;
                    })
                    .then(() => {
                        this.schedules.forEach(item => {
                            item.date_departure_at  = this.formatDate(item.departure_at);
                            item.date_arrival_at  = this.formatDate(item.arrival_at);
                        })
                    })

            },
            showModal(schedule) {
                if(! this.$laravel.hasAnyPermission('orders')){
                    return;
                }

                Promise.all([
                    this.getScheduleLocations(schedule),
                    this.getBookedSeats(schedule)
                ]).then(([routeLocationsResponse, bookedSeatsResponse]) => {
                    this.$modal.show(
                        OrderModal, //vue component
                        {   //component props
                            schedule: schedule,
                            locations: routeLocationsResponse.data.locations,
                            dataSeats: {
                                busy: bookedSeatsResponse.data.bookedSeats, // занятые места
                                chosen: bookedSeatsResponse.data.checkedSeats,
                                purchased: bookedSeatsResponse.data.purchasedSeats,
                                idle: ['В'], // недоступные места
                            },
                            title: this.text.createModalTitle,
                            refreshTable: this.find,
                            clientInfo: null,
                            update: false,
                            isArchive: this.isArchive
                        }, {   //modal settings
                            height: 'auto',
                            draggable: false,
                            adaptive: true,
                            scrollable: true,
                            clickToClose: false,
                        }
                    );
                });
            },

            showScheduleModal(schedule) {
                Promise.all([
                    this.getScheduleLocations(schedule),
                    this.getBookedSeats(schedule)
                ]).then(([routeLocationsResponse, bookedSeatsResponse]) => {
                    this.$modal.show(
                        ScheduleModal, //vue component
                        {   //component props
                            schedule: schedule,
                            locations: routeLocationsResponse.data.locations,
                            seats: {
                                busy: bookedSeatsResponse.data.bookedSeats, // занятые места
                                chosen: bookedSeatsResponse.data.checkedSeats,
                                purchased: bookedSeatsResponse.data.purchasedSeats,
                                idle: ['В'], // недоступные места
                            },
                            title: 'Список пасажирів на рейс',
                            refreshTable: this.find,
                            isArchive: this.isArchive
                        }, {   //modal settings
                            height: 'auto',
                            width: '70%',
                            draggable: false,
                            adaptive: true,
                            scrollable: true,
                            resizable: true
                        }, {
                            dataSaved() {
                                alert('data saved');
                            }
                        }
                    );
                });

            },

            searchLocations(query) {
                if(query.length > 1){
                    this.fetchLocations(query);
                }
            },

            fetchLocations(query) {
                axios.get('/api/locations/in-routes?query=' + query)
                    .then(({data}) => {
                        this.locations = data.locations;
                    });
            },

            getPopularLocations() {
                axios.get('/admin/locations/popular')
                    .then(({data}) => {
                       console.log(data);
                    });
            },

            setPopularLocations() {
               axios.post('/admin/locations/popular',
                {'location_ids': [1,2,3]})
                .then(({data}) => {
                    console.log(data);
                });
            },

            getBookedSeats(schedule) {
                return axios.post('/admin/schedules/booked-seats', {
                    schedule_id: schedule.id,
                    departure_at: schedule.departure_at,
                    arrival_at: schedule.arrival_at,
                })
            },

            getScheduleLocations(schedule) {
                return axios.post('/admin/schedules/locations', {
                    schedule_id: schedule.id,
                })
            },

            checkDriverRole(roles){
                if(roles[0] === 'driver'){
                    this.dryverType = true;
                }else{
                    this.dryverType = false;
                }
            }
        }
    }
</script>


<style scoped>
    .disabled-picker {
        background: #cacac0
    }
    .is_archive {
        background: #c6b8de;
    }
    .route-card-linkwrapp{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 10;
        cursor: pointer;
    }
</style>

