<template>
    <div class="modal-content">
        <div class="modal-header">
            <h4 class="modal-title">{{ title }}</h4>
            <button @click="$emit('close')" aria-label="Close" class="close" type="button">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <form @keydown="form.errors.clear()" class="form-horizontal">
                <div class="card-body">

                    <!--Имя-->
                    <div class="form-group row">
                        <label class="col-sm-2 col-form-label">{{ ('Ім’я') }}</label>
                        <div class="col-sm-10">
                            <input autofocus
                                   class="form-control"
                                   id="name"
                                   placeholder="Ім’я"
                                   required
                                   type="text"
                                   v-model="form.name"
                            >
                            <span class="invalid-feedback d-block"
                                  role="alert"
                                  v-if="hasError('name')"
                                  v-text="getError('name')"
                            />
                        </div>
                    </div>

                    <!--Должность-->
                    <div class="form-group row">
                        <label class="col-sm-2 col-form-label">{{ ('Посада') }}</label>
                        <div class="col-sm-10">
                            <select class="custom-select" v-model="form.role.name">

                                <option v-for="role in roles"
                                        v-bind:value="role.name">
                                    {{role.name}}
                                </option>

                            </select>
                            <span class="invalid-feedback d-block"
                                  role="alert"
                                  v-if="hasError('role.name')"
                                  v-text="getError('role.name')"/>
                        </div>
                    </div>

                    <!--Перевозчик-->
                    <div class="form-group row">
                        <label class="col-sm-2 col-form-label">{{ ('Перевізник') }}</label>
                        <div class="col-sm-10">
                            <select class="custom-select" v-model="form.carrier_id">
                                <option :value="null">Не вибрано</option>
                                <option v-for="carrier in carriers"
                                        :value="carrier.id">
                                    {{carrier.name}}
                                </option>

                            </select>
                            <span class="invalid-feedback d-block"
                                  role="alert"
                                  v-if="hasError('carrier.id')"
                                  v-text="getError('carrier.id')"/>
                        </div>
                    </div>

                    <!--Номер-->
                    <div class="form-group row">
                        <label class="col-sm-2 col-form-label">{{ ('Номер моб.') }}</label>
                        <div class="col-sm-10">
                            <input class="form-control"
                                   name="number"
                                   placeholder="Номер моб."
                                   required
                                   type="tel"
                                   v-model="form.number"
                            >
                            <span class="invalid-feedback d-block"
                                  role="alert"
                                  v-if="hasError('number')"
                                  v-text="getError('number')"
                            />
                        </div>
                    </div>

                    <!--Пароль-->
                    <div class="form-group row">
                        <label class="col-sm-2 col-form-label" for="password">{{('Пароль')}}</label>
                        <div class="col-sm-10">
                            <input class="form-control"
                                   id="password"
                                   required
                                   v-model="form.password"
                            >
                            <span class="invalid-feedback d-block"
                                  role="alert"
                                  v-if="hasError('password')"
                                  v-text="getError('password')"
                            />
                        </div>
                    </div>

                    <!--Подтверждение пароля-->
                    <div class="form-group row">
                        <label class="col-sm-2 col-form-label" for="password">{{('Підтвердити пароль')}}</label>
                        <div class="col-sm-10">
                            <input class="form-control"
                                   id="password-confirm"
                                   required
                                   v-model="form.password_confirmation"
                            >
                            <span class="invalid-feedback d-block"
                                  role="alert"
                                  v-if="hasError('password_confirmation')"
                                  v-text="getError('password_confirmation')"/>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <div class="modal-footer">
            <button class="btn btn-default" type="button"
                    @click="$emit('close')">
                {{ ('Закрити') }}
            </button>
            <button class="btn btn-info" type="button"
                    :disabled="form.errors.any()"
                    v-if="!user"
                    @click="create">
                {{ ('Додати користувача') }}
            </button>
            <button class="btn btn-info" type="button"
                    :disabled="form.errors.any()"
                    v-else
                    @click="edit(user.id)">
                {{ ('Редагувати користувача') }}
            </button>
        </div>
    </div>
</template>

<script>

    export default {
        props: ['roles', 'title', 'user', 'loadData', 'carriers'],

        data() {
            return {
                form: new Form({
                    name: '',
                    role: {
                        id: '',
                        name: ''
                    },
                    carrier_id: null,
                    number: '',
                    password: '',
                    password_confirmation: '',
                }),
                errors: null
            }

        },

        mounted() {
            if(!this.user) {
                return;
            }

            let currentUser = this.user;

            currentUser.role = this.user.roles[0];

            this.form = new Form(currentUser);
        },

        methods: {
            getError(key) {
                return this.errors[key][0]; //return first error
            },
            hasError(key) {
                return _.has(this.errors, key);
            },
            create() {
                axios
                    .post('/admin/users/create', this.form)
                    .then(({data}) => {
                        // this.user = data.DATA;
                        this.loadData();
                        this.$emit('close');

                        // location.reload()
                    })
                    .catch(e => {
                        this.errors = e.response.data.errors;

                        return false;
                    });
            },
            edit() {
                axios
                    .post(`/admin/users/update/${this.form.id}`, this.form)
                    .then(({data}) => {
                        this.loadData();
                        this.$emit('close');
                    }).catch(e => {
                        this.errors = e.response.data.errors;

                        return false;
                    });
            }
        }
    }
</script>

<style scoped>

</style>
