<template>
    <div class="content">
        <div class="container-fluid">
            <div class="card buses-card col-lg-12">

                <card-header :text="this.text" @showModal="showModal"/>
                <!-- /.card-header -->

                <div class="card-body table-responsive">
                    <table class="table table-bordered table-hover dataTable">
                        <thead>
                        <tr>
                            <th>{{ ('Назва') }}</th>
                            <th>{{ ('Тип') }}</th>
                            <th>{{ ('Кількість сидінь') }}</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="bus in array">
                            <td>{{bus.numeric}}</td>
                            <td>{{bus.type}}</td>
                            <td>{{bus.seats}}</td>
                            <td>
                                <ud-buttons
                                    :data="bus"
                                    @onDelete="onDelete"
                                    @onEdit="onEdit"
                                />
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <!-- /.card-body -->

            </div>
            <!-- /.card -->
        </div>
        <!-- /.container-fluid -->
    </div>
</template>

<script>
    import BusModal from './modal';
    import CardHeader from '../../components/Table/CardHeader';
    import udButtons from "../../components/udButtons";

    export default {
        components: {
            BusModal,
            'card-header': CardHeader,
            'ud-buttons': udButtons,
        },
        data() {
            return {
                text: {
                    createButtonText: 'Додати автобус',
                    cardTitle: 'Список автобусів',
                    createModalTitle: 'Створити новий автобус',
                    updateModalTitle: 'Редагувати автобус',
                },
                array: [],
                types: [],
            }
        },

        created() {
            this.loadData();
        },

        methods: {
            showModal(title, bus = null) {
                this.$modal.show(
                    BusModal, //vue component
                    {   //component props
                        array: this.array,
                        title: title,
                        bus: bus,
                        types: this.types,
                        loadData: this.loadData,

                    }, {   //modal settings
                        height: 'auto',
                        draggable: false,
                    }, {
                        'push': this.pushToTable,
                        'edit': this.editInTable
                    }

                );
            },
            //TODO это должно быть сделанно через ленивую загрузку
            loadData() {
                axios
                    .post('/admin/buses')
                    .then(({data}) => this.array = data.DATA.reverse());

                axios.post('/admin/buses/types')
                    .then(({data}) => this.types = data.DATA);
            },
            onEdit(obj) {
                axios.post(`/admin/buses/get/${obj.id}`)
                    .then(({data}) => this.showModal(this.text.updateModalTitle, data.DATA));
            },

            onDelete(obj) {
                axios.delete(`/admin/buses/delete/${obj.id}`)
                    .then(() => this.deleteFromTable(obj));
            },

            pushToTable(obj) {
                _.push(this.array, obj);
            },

            editInTable(obj) {
                let objIndex = _.findIndex(this.array, obj.id);
                this.array[objIndex] = obj;
            }
            ,
            deleteFromTable(obj) {
                this.array.splice(this.array.indexOf(obj), 1);
                Swal.fire('Вилучено!')
            },
        }
    }

</script>
