<template>
    <div class="card schedules-card">

        <card-header :text="this.text" @showModal="showModal"/>
        <!-- /.card-header -->

        <div class="card-body">
            <div role="tablist">

                <b-card no-body class="mb-1"
                    v-for="schedule in schedules"
                    :key="schedule.id">

                    <b-card-header header-tag="header" class="p-1" role="tab">

<!--                        <b-button block variant="light"-->
<!--                                  v-b-toggle="`accordion-${schedule.id}`"-->
<!--                        >-->

                            <!--Header schedule info-->
                            <div class="d-flex flex-row justify-content-between align-content-stretch flex-wrap p-2">
                                <div class="d-flex flex-row flex-wrap col-md-9">
                                    <div class="d-flex flex-column flex-nowrap justify-content-around col-md-4">
                                        <h3 class="font-weight-bold">
                                            {{schedule.location_start_name}} - {{schedule.location_end_name}}
                                        </h3>
                                        <h5>{{schedule.bus_type}} {{schedule.bus_numeric}}</h5>
                                    </div>

                                    <div class="d-flex flex-column flex-nowrap justify-content-around col-md-3">
                                        <h4 class="font-weight-bold">Відправлення</h4>
                                        <h6 class="date mr-2">{{ customParseDate(schedule, schedule.departure_at) }}</h6>
                                    </div>

                                    <div class="d-flex flex-column flex-nowrap justify-content-around col-md-3">
                                        <h4 class="font-weight-bold">Прибуття</h4>
                                        <h6 class="date mr-2">{{ customParseDate(schedule, schedule.arrival_at) }}</h6>
                                    </div>
                                    <div class="d-flex flex-column flex-nowrap justify-content-around col-md-2">
                                        <h4 class="font-weight-bold">Liqpay</h4>
                                        <h6>{{ schedule.use_liqpay ? 'Увімкнено' : 'Вимкнено' }}</h6>
                                    </div>
                                </div>


                                <ud-buttons class="flex-self-end"
                                            :data="schedule"
                                            :enableCopy="true"
                                            @onDelete="onDelete"
                                            @onCopy="onCopy"
                                            @onEdit="onEdit"
                                />
                            </div>
<!--                        </b-button>-->
                    </b-card-header>

<!--                    <b-collapse :id="`accordion-${schedule.id}`" accordion="my-accordion" role="tabpanel">-->
<!--                        <b-card-body>-->
<!--                            <b-card-text>{{ text.cardTitle }}</b-card-text>-->
<!--                        </b-card-body>-->
<!--                    </b-collapse>-->

                </b-card>
            </div>
        </div>
        <!-- /.card-body -->

    </div>
    <!-- /.card -->

</template>

<script>

    import modal from './modal';
    import CardHeader from '../../components/Table/CardHeader';
    import udButtons from "../../components/udButtons";
    import OrderModal from "../bookings/modal";

    // import {VuejsDatatableFactory} from 'vuejs-datatable';


    export default {
        components: {
            modal,
            // 'datatable': VuejsDatatableFactory,
            'card-header': CardHeader,
            'ud-buttons': udButtons
        },
        data() {

            return {
                text: {
                    cardTitle: 'Маршрути',
                    createButtonText: 'Створити маршрут',
                    createModalTitle: 'Створення розкладу',
                    updateModalTitle: 'Редагування розкладу',
                },
                schedules: [],
            }

        },

        created() {
            this.loadData();
        },

        methods: {
            loadData() {
                axios.post('/admin/schedules').then( ({data}) => this.schedules = data.reverse() );
            },
            customParseDate(schedule, dateTime) {
                if(!!+schedule.every_monday || !!+schedule.every_tuesday || !!+schedule.every_wednesday
                    || !!+schedule.every_thursday || !!+schedule.every_friday || !!+schedule.every_saturday
                    || !!+schedule.every_sunday) {

                    let result = '';

                    this.$dayList.forEach(function(item, index) {
                        if(!!+schedule[item.column]) {
                            result +=  item.name + ', '
                        }
                    });

                    return result + this.$moment(dateTime).format('HH:mm');
                } else {
                    return this.$moment(dateTime).format('DD.MM.YYYY HH:mm');
                }
            },
            pushToList(schedule) {
                this.schedules.push(schedule);
            },
            editInList(schedule) {
                let objIndex = this.schedules.findIndex((obj => obj.id === schedule.id));
                this.schedules[objIndex] = schedule;
            },
            deleteFromList(obj) {
                Swal.fire(
                    'Видалено!',
                    'Розклад був видалений!.',
                    'success'
                )
            },

            showModal(title, schedule = null) {
                this.$modal.show(
                    modal, //vue component
                    {   //component props
                        schedules: this.schedules,
                        title: title,
                        schedule: schedule,
                        loadData: this.loadData
                    }, {   //modal settings
                        height: 'auto',
                        draggable: false,
                        adaptive: true,
                        scrollable: true,
                        clickToClose: false,
                    }

                );
            },

            onEdit(schedule) {
                axios.post(`/admin/schedules/get/${schedule.id}`)
                    .then(({data}) =>
                        this.showModal(this.text.updateModalTitle, data.DATA)
                    );
            },

            onCopy(schedule) {
                axios.post(`/admin/schedules/get/${schedule.id}`)
                    .then(({data}) => {
                        delete data.DATA.id;

                        this.showModal(this.text.createModalTitle, data.DATA)
                    });
            },

            onDelete(schedule) {
                axios.delete(`/admin/schedules/delete/${schedule.id}`)
                    .then(() => this.loadData());
            },
        },
    }

</script>
