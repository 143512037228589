<template>
    <div class="card">

        <div class="card-body">
            <div role="tablist">
                <div v-for="bus in buses" class="bus-scheme_item">
                    <h2 class="bus-scheme_item_title">{{ bus.name }}</h2>
                    <bus-generator
                        :size="false"
                        :sale="false"
                        v-bind:schema="bus.schema"
                    ></bus-generator>
                </div>


<!--                <div class="bus-scheme_item">-->
<!--                    <h2 class="bus-scheme_item_title">Тип 1</h2>-->
<!--                    <bus-generator-->
<!--                        :size="false"-->
<!--                        :sale="false"-->
<!--                        v-bind:type="1"-->
<!--                    ></bus-generator>-->
<!--                </div>-->

<!--                <div class="bus-scheme_item">-->
<!--                    <h2 class="bus-scheme_item_title">Тип 2</h2>-->
<!--                    <bus-generator-->
<!--                        :size="false"-->
<!--                        :sale="false"-->
<!--                        v-bind:type="2"-->
<!--                    ></bus-generator>-->
<!--                </div>-->

<!--                <div class="bus-scheme_item">-->
<!--                    <h2 class="bus-scheme_item_title">Тип 3</h2>-->
<!--                    <bus-generator-->
<!--                        :size="false"-->
<!--                        :sale="false"-->
<!--                        v-bind:type="3"-->
<!--                    ></bus-generator>-->
<!--                </div>-->

<!--                <div class="bus-scheme_item">-->
<!--                    <h2 class="bus-scheme_item_title">Тип 4</h2>-->
<!--                    <bus-generator-->
<!--                        :size="false"-->
<!--                        :sale="false"-->
<!--                        v-bind:type="4"-->
<!--                    ></bus-generator>-->
<!--                </div>-->

<!--                <div class="bus-scheme_item">-->
<!--                    <h2 class="bus-scheme_item_title">Тип 5</h2>-->
<!--                    <bus-generator-->
<!--                        :size="false"-->
<!--                        :sale="false"-->
<!--                        v-bind:type="5"-->
<!--                    ></bus-generator>-->
<!--                </div>-->

<!--                <div class="bus-scheme_item">-->
<!--                    <h2 class="bus-scheme_item_title">18 мест. Тип 1</h2>-->
<!--                    <bus-generator-->
<!--                        :size="false"-->
<!--                        :sale="false"-->
<!--                        v-bind:type="6"-->
<!--                    ></bus-generator>-->
<!--                </div>-->

<!--                <div class="bus-scheme_item">-->
<!--                    <h2 class="bus-scheme_item_title">20 мест. Тип 1</h2>-->
<!--                    <bus-generator-->
<!--                        :size="false"-->
<!--                        :sale="false"-->
<!--                        v-bind:type="7"-->
<!--                    ></bus-generator>-->
<!--                </div>-->

<!--                <div class="bus-scheme_item">-->
<!--                    <h2 class="bus-scheme_item_title">20 мест. Тип 2</h2>-->
<!--                    <bus-generator-->
<!--                        :size="false"-->
<!--                        :sale="false"-->
<!--                        v-bind:type="8"-->
<!--                    ></bus-generator>-->
<!--                </div>-->

<!--                <div class="bus-scheme_item">-->
<!--                    <h2 class="bus-scheme_item_title">40 мест. MAN Ayats</h2>-->
<!--                    <bus-generator-->
<!--                        :size="false"-->
<!--                        :sale="false"-->
<!--                        v-bind:type="9"-->
<!--                    ></bus-generator>-->
<!--                </div>-->

<!--                <div class="bus-scheme_item">-->
<!--                    <h2 class="bus-scheme_item_title">31 место. Тип 1</h2>-->
<!--                    <bus-generator-->
<!--                        :size="false"-->
<!--                        :sale="false"-->
<!--                        v-bind:type="10"-->
<!--                    ></bus-generator>-->
<!--                </div>-->

<!--                <div class="bus-scheme_item">-->
<!--                    <h2 class="bus-scheme_item_title">31 место. Тип 2</h2>-->
<!--                    <p>Схема не задана</p>-->
<!--                    &lt;!&ndash;<bus-generator-->
<!--                        :size="false"-->
<!--                        :sale="false"-->
<!--                        v-bind:type="11"-->
<!--                    ></bus-generator>&ndash;&gt;-->
<!--                </div>-->

<!--                <div class="bus-scheme_item">-->
<!--                    <h2 class="bus-scheme_item_title">55 мест. Ванхол</h2>-->
<!--                    <bus-generator-->
<!--                        :size="false"-->
<!--                        :sale="false"-->
<!--                        v-bind:type="12"-->
<!--                    ></bus-generator>-->
<!--                </div>-->

<!--                <div class="bus-scheme_item">-->
<!--                    <h2 class="bus-scheme_item_title">31 место. Атаман</h2>-->
<!--                    <bus-generator-->
<!--                        :size="false"-->
<!--                        :sale="false"-->
<!--                        v-bind:type="13"-->
<!--                    ></bus-generator>-->
<!--                </div>-->

            </div>
        </div>
        <!-- /.card-body -->

    </div>
    <!-- /.card -->
</template>

<script>
    import BusGenerator from '../busgen';

    export default {
        components: {
            'bus-generator': BusGenerator,
        },
        computed: {
            buses () {
                return this.$store.state.buses.list
            }
        },

        serverPrefetch () {
            // возвращает Promise из действия, поэтому
            // компонент ждёт данные перед рендерингом
            return this.fetchBuses()
        },

        mounted () {
            if (! this.buses) {
                this.fetchBuses()
            }
        },

        methods: {
            fetchBuses () {
                return this.$store.dispatch('GET_ALL_BUSES');
            }
        },

        data() {

            return {
            }

        },
    }

</script>
