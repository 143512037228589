<template>
    <div class="bus_container" :hidden="hidden" v-bind:class="[classes, { big: size }]">
        <div class="bus_block">
            <div class="bus_border"><span>Перед</span></div>

            <div class="bus_row"
                 v-for="row in schema"
            >
                <div v-bind:class="'bus_item ' + getRoleClass(item) + ''"
                    v-for="(item, itemIndex) in row"
                >
                    <a v-if="item.f"
                        v-bind:class="getItemClass(item)"
                        v-bind:key="item.n"
                        :data-value="item.n"
                       @click="bookSeat(item)"
                       v-html="item.n"
                    ></a>
                </div>
            </div>
            <div class="bus_border"><span>Зад</span></div>
        </div>
    </div>
</template>

<script>
    import { ROLES, ROLES_BUTTONS, ROLES_NAMES } from '../bus.constants';
    export default {
        props: {
            classes: {
                default: '',
                type: String
            },
            size: {
                default: true,
                type: Boolean
            },
            places: {
                default: function () {
                    return {busy:[], idle: [this.driver, this.enter]};
                },
                type: Object
            },
            form: {
                type: Object
            },
            sale: {
                default: true,
                type: Boolean
            },
            multiply: {
                default: false,
                type: Boolean
            },
            schema: {

            },

        },

        data() {
            return {
                types: [],
                hidden: true,
                selectedPlaces: [],
            }

        },

        created() {
            this.buildScheme(this.places);
        },

        methods: {
            buildScheme(places) {
                this.driver = `<i class="fas fa-dharmachakra"></i>`;
                this.enter = `<i class="fas fa-door-open"></i>`;

                this.schema.forEach(row => {
                    row.forEach(place => {
                        this.setDefault(place);

                        places.busy.forEach(n => {
                            if(n === place.n) {
                                this.setBusy(place);
                            }
                        });
                        if (places.hasOwnProperty('purchased')) {
                            places.purchased.forEach(n => {
                                if(n === place.n) {
                                    this.setPurchased(place);
                                }
                            });
                        }
                        if (places.hasOwnProperty('chosen')) {
                            places.chosen.forEach(n => {
                                if(n === place.n) {
                                    this.setChosen(place);
                                }
                            });
                        }

                        places.idle.forEach(n => {
                            if(n === place.n) {
                                this.setIdle(place)
                            }
                            if (place.n === 'driver') {
                                place.n = this.driver;
                            }

                            if (place.n === 'enter') {
                                place.n = this.enter;
                            }
                        })
                    })
                });

                this.hidden = false;
            },
            getRoleClass(item) {
                return ROLES[Math.ceil(item.f)];
            },
            getItemClass(item) {
                return ROLES_BUTTONS[item.s];
            },
            bookSeat(item) {
                if (item.n === this.driver || item.n === this.enter) {
                    return false;
                }

                this.$emit('selectedBusSlot', item);

                if(!this.sale || this.isBusy(item) || this.isPurchased(item)) {
                    return false;
                }

                this.schema.forEach(row => {
                    row.forEach(place => {
                        if(!this.multiply && this.isChosen(place)) {
                            this.setDefault(place);
                        }
                    })
                })

                if (this.isDefault(item)) {
                    this.setChosen(item);
                    this.selectedPlaces.push(item.n);
                } else {
                    this.setDefault(item);
                    this.selectedPlaces.splice(this.selectedPlaces.indexOf(item.n), 1);
                }

                if(this.form) {
                    this.form.seat_index = this.selectedPlaces;
                }

                this.refreshItem(item)
            },
            isDefault(item) {
                return item.s === ROLES_NAMES['default'];
            },
            isBusy(item) {
                return item.s === ROLES_NAMES['busy'];
            },isPurchased(item) {
                return item.s === ROLES_NAMES['purchased'];
            },
            isIdle(item) {
                return item.s === ROLES_NAMES['idle'];
            },
            isChosen(item) {
                return item.s === ROLES_NAMES['chosen'];
            },
            isDisabled(item) {
                return item.s === ROLES_NAMES['disabled'];
            },
            setDefault(item) {
                item.s = ROLES_NAMES['default'];
            },
            setBusy(item) {
                item.s = ROLES_NAMES['busy'];
            },
            setPurchased(item) {
                item.s = ROLES_NAMES['purchased'];
            },
            setIdle(item) {
                item.s = ROLES_NAMES['idle'];
            },
            setChosen(item) {
                item.s = ROLES_NAMES['chosen'];
            },
            setDisabled(item) {
                item.s = ROLES_NAMES['disabled'];
            },
            refreshItem(item) {
                item.f = Math.random();
            }
        },
    }

</script>
