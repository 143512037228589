<template>
    <div class="d-flex flex-row flex-wrap justify-content-end">
        <a @click="$emit('onEdit', data)" class="btn btn-app btn-app_icon">
            <i class="fas fa-edit"></i>
        </a>
        <a @click="$emit('onCopy', data)" class="btn btn-app btn-app_icon" v-if="enableCopy">
            <i class="fas fa-copy"></i>
        </a>
        <a @click="onDelete" class="btn btn-app btn-app_icon">
            <i class="fas fa-trash"></i>
        </a>
    </div>
</template>

<script>
    export default {
        props: ['data', 'enableCopy'],
        name: "udButtonsComponent",


        methods: {
            onDelete() {
                Swal.fire({
                    title: 'Ви впевнені, що хочете видалити?',
                    text: 'Ви не зможете цього відкотити назад!',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Так, видалити!',
                    cancelButtonText: 'Скасування',
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.$emit('onDelete', this.data);
                    }
                })
            }
        }


    }
</script>

<style scoped>

</style>
