<template>
    <div class="card container card-booking">

<!--        <card-header :text="this.text"/>-->
        <!-- /.card-header -->

        <div class="card-body">
            <!--Search area-->
            <form @keydown="form.errors.clear()" class="form-horizontal booking-search">
                    <!--Пункт отправления / Пункт прибытия -->
                    <div class="booking-search__wrapp">
                        <div class="form-group from full-search">
                            <label class="form-label">{{ ('Додати популярне направлення') }}</label>
                            <multiselect
                                v-model="form.location_start"
                                :options="locations"
                                placeholder="Додати пункт"
                                @search-change="searchLocations"
                                :internal-search="false"
                                :allow-empty="false"
                                :max-height="165"
                                label="location_start">
                                <template slot="singleLabel" slot-scope="props">
                                        <span class="option__desc">
                                            <span class="option__title" v-html="props.option.full_name"></span>
                                        </span>
                                </template>

                                <template slot="option" slot-scope="props">
                                    <div class="option__desc">
                                        <span class="option__title" v-html="props.option.full_name"></span>
                                    </div>
                                </template>
                            </multiselect>
                        </div>
                        <b-button class="submit-btn" @click="addRoute">Додати</b-button>
                    </div>
            </form>

            <div class="popular-directions">
                <div class="popular-directions_heading">
                    Вже додані направлення
                </div>
                <div class="popular-directions_list">
                    <div class="popular-directions_item" v-for="(location, index) in popularLocations" :key="location.id">
                        <div class="name">{{location.full_name}}</div>
                        <button class="popular-directions_delete" @click="deleteRoute(location)">
                            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M28 12L12 28" stroke="#2C2A44" stroke-width="2"/>
                                <path d="M12 12L28 28" stroke="#2C2A44" stroke-width="2"/>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    import CardHeader from '../../components/Table/CardHeader';
    import Multiselect from 'vue-multiselect'

    export default {
        props: ['title', 'schedule'],
        components: {
            'card-header': CardHeader,
            Multiselect
        },

        data() {
            return {
                checkDay: false,
                isArchive: true,
                text: {
                    cardTitle: 'Бронювання',
                    createButtonText: 'Забронювати',
                    createModalTitle: 'Забронювати місце',
                    updateModalTitle: 'Редагувати бронь',
                },
                form: new Form({
                    location_start: '',
                    location_end: '',
                    departure_at: '',
                }),
                departureAt: {
                    date: this.$moment().format(),
                    time: ''
                },
                locations: [],
                popularLocations: [],
                schedules: [],
                week: [],
            }

        },
        //TODO возможно из этого сделать ленивую загруз
        async created() {
            this.getPopularLocations();
        },

        methods: {

            searchLocations(query) {
                if(query.length > 1){
                    this.fetchLocations(query);
                }
            },

            fetchLocations(query) {
                axios.get('/api/locations/?query=' + query)
                    .then(({data}) => {
                        this.locations = data.locations;
                    });
            },

            getPopularLocations() {
                axios.get('/admin/locations/popular')
                    .then(({data}) => {
                        this.popularLocations = data.DATA;
                    });
            },

            setPopularLocations(ids) {
               axios.post('/admin/locations/popular',
                {'location_ids': ids})
                .then(({data}) => {
                    console.log(data);
                });
            },

            addRoute(){
                let locationsIds = [];
                if(this.form.location_start){
                    this.popularLocations.push(this.form.location_start);
                }
                this.popularLocations.forEach(place => {
                    locationsIds.push(place.id);
                });

                this.setPopularLocations(locationsIds);
            },
            deleteRoute(place){
                const arr = this.popularLocations;

                const newArr = arr.filter(object => {
                    return object.id !== place.id;
                });
                this.popularLocations = newArr;
                this.addRoute();
            },
        }
    }
</script>


<style scoped>
    .disabled-picker {
        background: #cacac0
    }
    .is_archive {
        background: #c6b8de;
    }
    .full-search{
        width: 39% !important;
    }
    .popular-directions_heading{
        font-size: 16px;
        line-height: 24px;
        color: #393D5C;
        font-family: "Euclid Circular Medium", Arial, sans-serif;
        margin-bottom: 16px;
        font-weight: initial;
    }
    .popular-directions_list{
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    .popular-directions_item{
        width: 45%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 12px;
    }
    .popular-directions_delete{
        background: #e14646;
        width: 30px;
        height: 30px;
        border-radius: 3px;
        border: none;
        margin: 0;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        margin-left: 16px;
    }
    .popular-directions_item .name{
        font-size: 14px;
    }
    .popular-directions_delete path{
        stroke: #fff;
    }
    .booking-search__wrapp{
        justify-content: flex-start !important;
    }
    .booking-search__wrapp .submit-btn{
        margin-left: 24px !important;
    }

    @media screen and (max-width:992px){
        .full-search{
            width: 100% !important;
        }
        .popular-directions_list{
            display: block;
        }
        .popular-directions_item{
            width: 100%;
        }
    }
</style>

