<template>
    <div style="width: 150px;"></div>
    <!--<div class="сard-tools">
        <div class="input-group input-group-sm" style="width: 150px;">
            <input class="form-control float-right" name="table_search" placeholder="Поиск"
                   type="text">

            <div class="input-group-append">
                <button class="btn btn-default" type="submit"><i class="fas fa-search"></i>
                </button>
            </div>
        </div>
    </div>-->
</template>

<script>
    export default {
        name: "CardTools"
    }
</script>

<style scoped>

</style>
