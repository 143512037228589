<template>
    <div class="modal-content">
        <div class="modal-header">
            <h4 class="modal-title">{{ title }}</h4>
            <button @click="$emit('close')" aria-label="Close" class="close" type="button">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <form @keydown="form.errors.clear()" class="form-horizontal">
                <div class="card-body">

                    <!--Название маршрута-->
                    <div class="form-group">
                        <label class="form-label">{{ ('Маршрут') }}</label>
                        <input class="form-control"
                               placeholder="Маршрут"
                               autofocus
                               type="text"
                               v-model="form.name"
                        >
                        <span class="invalid-feedback d-block"
                              role="alert"
                              v-if="form.errors.has('name')"
                              v-text="form.errors.get('name')"
                        />
                    </div>

                    <!--Пункт отправления / Пункт прибытия -->
                    <div class="d-flex flex-row flex-wrap justify-content-between">
                        <div class="form-group location-group">
                            <label class="form-label">{{ ('Пункт відправки') }}</label>
                            <multiselect
                                v-model="form.main_route.location_start"
                                :options="locations"
                                placeholder="Пункт відправки"
                                @search-change="searchLocations"
                                :internal-search="false"
                                :allow-empty="false"
                                :max-height="165"
                                label="location_start">
                                <template slot="singleLabel" slot-scope="props">
                                        <span class="option__desc">
                                            <span class="option__title" v-html="props.option.full_name"></span>
                                        </span>
                                </template>

                                <template slot="option" slot-scope="props">
                                    <div class="option__desc">
                                        <span class="option__title" v-html="props.option.full_name"></span>
                                    </div>
                                </template>
                            </multiselect>
                            <span class="invalid-feedback d-block"
                                  role="alert"
                                  v-if="form.errors.has('location_start')"
                                  v-text="form.errors.get('location_start')"
                            />
                        </div>
                        <div class="form-group location-group">
                            <label class="form-label">{{ ('Пункт прибуття') }}</label>
                            <multiselect
                                v-model="form.main_route.location_end"
                                :options="locations"
                                placeholder="Пункт прибуття"
                                @search-change="searchLocations"
                                :internal-search="false"
                                :allow-empty="false"
                                :max-height="165"
                                label="location_end">
                                <template slot="singleLabel" slot-scope="props">
                                        <span class="option__desc">
                                            <span class="option__title" v-html="props.option.full_name"></span>
                                        </span>
                                </template>

                                <template slot="option" slot-scope="props">
                                    <div class="option__desc">
                                        <span class="option__title" v-html="props.option.full_name"></span>
                                    </div>
                                </template>
                            </multiselect>
                            <span class="invalid-feedback d-block"
                                  role="alert"
                                  v-if="form.errors.has('location_end')"
                                  v-text="form.errors.get('location_end')"
                            />
                        </div>
                    </div>
                    <div class="d-flex flex-row flex-wrap justify-content-between">
                        <div class="form-group location-group">
                            <label class="form-label">{{ ('Адреса відправлення') }}</label>
                            <input type="text" class="form-control" v-model="form.main_route.start_address">
                            <span class="invalid-feedback d-block"
                                  role="alert"
                                  v-if="form.errors.has('main_route.start_address')"
                                  v-text="form.errors.get('main_route.start_address')"
                            />
                        </div>
                        <div class="form-group location-group">
                            <label class="form-label">{{ ('Адреса прибуття') }}</label>
                            <input type="text" class="form-control" v-model="form.main_route.end_address">
                            <span class="invalid-feedback d-block"
                                  role="alert"
                                  v-if="form.errors.has('main_route.end_address')"
                                  v-text="form.errors.get('main_route.end_address')"
                            />
                        </div>
                    </div>
                    <hr>

                    <!--Время отправления-->
                    <div class="row departure-datetime d-flex flex-row flex-wrap justify-content-between">

                        <div class="chose-date_block col-md-7 disabled">
                            <label class="form-label">{{ ('Дата відправлення') }}</label>
                            <b-form-datepicker
                                v-bind:disabled="this.checkDay"
                                :start-weekday="1"
                                v-model="departureAt.date"
                                placeholder="Виберіть дату"
                                locale="uk"
                                v-bind:class="classDatePicker"
                            ></b-form-datepicker>
                        </div>

                        <div class="time col-md-5 chose-time_block">
                            <label class="form-label">{{ ('Відправлення') }}</label>
                            <input class="form-control"
                                   type="time"
                                   v-model="departureAt.time"
                            >
                        </div>

                    </div>
                    <!-- .departure-datetime -->
                    <hr>

                    <!--Время прибытия-->
                    <div class="row arrival-datetime d-flex flex-row flex-wrap justify-content-between">

                        <div class="chose-date_block col-md-7">
                            <label class="form-label">{{ ('Дата прибуття') }}</label>
                            <b-form-datepicker
                                v-bind:class="classDatePicker"
                                v-bind:disabled="this.checkDay"
                                :start-weekday="1"
                                v-model="arrivalAt.date"
                                placeholder="Виберіть дату"
                                locale="uk"
                            ></b-form-datepicker>
                        </div>

                        <div class="time col-md-5 chose-time_block">
                            <label class="form-label">{{ ('Прибуття') }}</label>
                            <input class="form-control"
                                   type="time"
                                   v-model="arrivalAt.time"
                            >
                        </div>

                    </div>
                    <!-- .arrival-datetime -->
                    <hr>


                    <b-button class="mb-4" @click="openRoutePointModal" variant="secondary">
                        Додати точку маршруту
                    </b-button>

                    <h5>Точки маршрута</h5>
                    <div class="d-flex flex-row flex-wrap justify-content-start">
                        <div v-for="(routePoint, index) in routePoints">
                            <div class="col route-point">
                                <div class="route-nav">
                                    <button class="edit-btn" v-on:click="openRoutePointModalEdit(index)" type="button">
                                        <i class="fas fa-edit"></i>
                                    </button>
                                    <button v-on:click="removeRoutePoint($event,index)" aria-label="Close" class="close" type="button">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="name">{{routePoint.point.name}}</div>
                                <div class="col">
                                    <p>{{ routePoint.departure_at | moment('HH:mm')}}</p>
                                    <p> - </p>
                                    <p>{{ routePoint.arrival_at | moment('HH:mm')}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr>

                    <!--Дни недели-->
                    <div class="d-flex flex-row flex-wrap justify-content-between align-content-center">
                        <!-- <div class="custom-control custom-checkbox"
                             v-for="(day, column) in week_days">
                            <label class="custom-control-label">{{day.name}}</label>
                            <input class="custom-control-input" type="checkbox" v-bind:name=day.column v-model="form[column]">
                        </div> -->

                        <b-form-checkbox v-model="form.every_monday">Пн</b-form-checkbox>
                        <b-form-checkbox v-model="form.every_tuesday">Вт</b-form-checkbox>
                        <b-form-checkbox v-model="form.every_wednesday">Ср</b-form-checkbox>
                        <b-form-checkbox v-model="form.every_thursday">Чт</b-form-checkbox>
                        <b-form-checkbox v-model="form.every_friday">Пт</b-form-checkbox>
                        <b-form-checkbox v-model="form.every_saturday">Сб</b-form-checkbox>
                        <b-form-checkbox v-model="form.every_sunday">Нд</b-form-checkbox>
                    </div>
                    <hr>

                    <!--Перевозчик-->
                    <div class="form-group">
                        <label class="form-label">{{ ('Прикріпити розклад до перевізника') }}</label>
                        <model-list-select
                            :list="carriers"
                            v-model="form.carrier_id"
                            option-value="id"
                            option-text="name"
                            placeholder="Виберіть перевізника"
                        ></model-list-select>
                    </div>

                    <!--Автобусы-->
                    <div class="form-group">
                        <label class="form-label">{{ ('Прикріпити авто до розкладу') }}</label>
                        <model-list-select
                            :list="buses"
                            v-model="form.bus_id"
                            option-value="id"
                            option-text="numeric"
                            placeholder="Виберіть автобус"
                        ></model-list-select>
                    </div>

                    <!--Водители-->
                    <div class="form-group">
                        <label class="form-label">{{ ('Прикріпити водія до розкладу') }}</label>
                        <model-list-select
                            :list="drivers"
                            v-model="form.driver_id"
                            option-value="id"
                            option-text="name"
                            placeholder="Виберіть водія"
                        ></model-list-select>
                    </div>

                    <div class="form-group">
                        <label class="form-label">{{ ('Використовувати liqpay') }}</label>
                        <model-list-select
                            :list="this.statusOptions"
                            v-model="form.use_liqpay"
                            option-value="id"
                            option-text="name"
                            placeholder="Виберіть"
                        ></model-list-select>
                    </div>

                </div>
            </form>
        </div>

        <!--Create button-->
        <div class="modal-footer">
            <button
                class="btn btn-default" type="button"
                @click="$emit('close')">
                {{ ('Відміна') }}
            </button>
            <button
                v-if="!schedule || !schedule.hasOwnProperty('id')"
                class="btn btn-info" type="button"
                :disabled="form.errors.any()"
                @click="create"
            >
                {{ ('Далі') }}
            </button>
            <button
                v-else
                class="btn btn-info" type="button"
                :disabled="form.errors.any()"
                @click="edit(schedule.id)"
            >
                {{ ('Змінити') }}
            </button>
        </div>

    </div>
</template>

<script>
    import routeModal from './route-modal';
    import routeModalEdit from './route-modal-edit';
    import ModalPrice from './modal-price';
    import { ModelListSelect } from 'vue-search-select';
    import Multiselect from 'vue-multiselect';
    import 'vue-search-select/dist/VueSearchSelect.css'

    export default {
        props: ['schedules', 'title', 'schedule', 'loadData'],
        components: {
            routeModal,
            routeModalEdit,
            ModalPrice,
            'model-list-select': ModelListSelect,
            Multiselect
        },

        data() {
            return {
                checkDay: false,
                week_days: [
                    {name: 'Пн', column: 'every_monday'},
                    {name: 'Вт', column: 'every_tuesday'},
                    {name: 'Ср', column: 'every_wednesday'},
                    {name: 'Чт', column: 'every_thursday'},
                    {name: 'Пт', column: 'every_friday'},
                    {name: 'Сб', column: 'every_saturday'},
                    {name: 'Нд', column: 'every_sunday'},
                ],
                form: new Form({
                    name: '',
                    main_route: {
                        location_start: null,
                        location_end: null,
                        start_address: '',
                        end_address: ''
                    },
                    use_liqpay: 0,
                    departure_at: '',
                    arrival_at: '',
                    every_monday: false,
                    every_tuesday: false,
                    every_wednesday: false,
                    every_thursday: false,
                    every_friday: false,
                    every_saturday: false,
                    every_sunday: false,
                    bus_id: '',
                    carrier_id: null,
                    driver_id: null
                }),
                departureAt: {
                    date: '',
                    time: ''
                },
                arrivalAt: {
                    date: '',
                    time: ''
                },
                routePoints: [
                //{name: '', arrival_at: '',departure_at: ''}
                ],
                statusOptions: [
                    {id: false, name: 'Вимкнути'},
                    {id: true, name: 'Увімкнути'},
                ],
                carriers: [],
                buses: [],
                drivers: [],
                week: [],
                locations: []
            }

        },

        computed: {
            classDatePicker: function () {
                this.checkDay = !!+(!!+this.form.every_monday || !!+this.form.every_tuesday || !!+this.form.every_wednesday || !!+this.form.every_thursday || !!+this.form.every_friday || !!+this.form.every_saturday || !!+this.form.every_sunday);

                if(this.checkDay) {
                    this.arrivalAt.date = null;
                    this.form.arrival_at = null;
                    this.departureAt.date = null;
                    this.form.departure_at = null;
                }

                return {
                    'disabled-picker': this.checkDay,
                }
            }
        },

        watch: {
            'form.carrier_id': function () {
                this.loadDrivers();
            }
        },

        mounted() {
            this.loadBuses();
            this.loadCarriers();

            if (!this.schedule) {
                return;
            }

            this.form = new Form(this.schedule);
            this.departureAt = {
                date: new Date(this.schedule.departure_at),
                time: this.$moment(this.schedule.departure_at).format('HH:mm')
            };

            this.arrivalAt = {
                date: new Date(this.schedule.arrival_at),
                time: this.$moment(this.schedule.arrival_at).format('HH:mm')
            };

            axios.post('/admin/schedules/locations', {
                schedule_id: this.schedule.id,
            }).then(({data}) => {
                this.routePoints = Object.values(data.locations.routePoints);
            })
        },


        methods: {
            loadBuses() {
                axios.post('/admin/buses').then(({data}) => this.buses = data.DATA);
            },

            loadCarriers(){
                axios.post('/admin/carriers').then(({data}) => this.carriers = data.carriers)
            },
            loadDrivers() {
                axios.post('/admin/users/get-by-role/driver', {
                    carrier_id: this.form.carrier_id
                }).then(({data}) => this.drivers = data);
            },

            removeRoutePoint(event, index) {
                this.routePoints.splice(index, 1);
            },
            openRoutePointModal() {
                this.$modal.show(
                    routeModal, //vue component
                    {   //modal props
                        routePoints: this.routePoints,
                        searchLocations: this.searchLocations,
                        locations: this.locations
                    }, {   //modal settings
                        height: 'auto',
                        draggable: false,
                        adaptive: true,
                    }
                );
            },
            openRoutePointModalEdit(index) {
                this.$modal.show(
                    routeModalEdit, //vue component
                    {   //modal props
                        selectedPoint: this.routePoints[index],
                        selectedIndex: index,
                        routePoints: this.routePoints,
                        searchLocations: this.searchLocations,
                        locations: this.locations,
                        callback: this.handleCallback
                    }, {   //modal settings
                        height: 'auto',
                        draggable: false,
                        adaptive: true,
                    }
                );
            },

            handleCallback(data) {
                // this.routePoints.splice(data.index, 1);
                // this.routePoints[data.index] = data.routePoint;
                let updatedRoutePoints = [...this.routePoints];
                updatedRoutePoints[data.index] = data.routePoint;
                this.routePoints = updatedRoutePoints;
            },

            parseRequestDate() {
                if (this.departureAt.date != null) {
                    this.form.departure_at = this.$moment(this.departureAt.date).format('YYYY-MM-DD') + ' ' + this.departureAt.time
                } else {
                    this.form.departure_at = this.departureAt.time;
                }


                if (this.arrivalAt.date != null) {
                    this.form.arrival_at = this.$moment(this.arrivalAt.date).format('YYYY-MM-DD') + ' ' + this.arrivalAt.time;
                } else {
                    this.form.arrival_at = this.arrivalAt.time;
                }
            },

            create() {
                this.parseRequestDate();

                axios.post('/admin/routes/create', {
                            form: this.form,
                            routePoints: this.routePoints
                        })
                    .then(({data}) => {
                        this.$modal.show(
                            ModalPrice, //vue component
                            {   //modal props
                                schedule: this.form,
                                mainRoute: data.DATA.mainRoute,
                                routesArray: data.DATA.routes,
                                schedules: this.schedules,
                                closeModal: this.closeModal,
                                loadData: this.loadData,
                                update: false,

                            }, {   //modal settings
                                height: 'auto',
                                draggable: false,
                                adaptive: true,
                                scrollable: true,
                            }, {
                                'onPush': this.onPush
                            }
                        );
                    });
            },

            onPush(schedule){
                this.schedules.push(schedule);
                this.$emit('close');
            },
            closeModal() {
                this.$emit('close');
            },
            edit() {
                this.parseRequestDate();

                axios.post('/admin/routes/create', {
                    form: this.form,
                    routePoints: this.routePoints
                })
                .then(({data}) => {
                    this.$modal.show(
                        ModalPrice, //vue component
                        {   //modal props
                            schedule: this.form,
                            mainRoute: data.DATA.mainRoute,
                            routesArray: data.DATA.routes,
                            schedules: this.schedules,
                            closeModal: this.closeModal,
                            loadData: this.loadData,
                            update: true

                        }, {   //modal settings
                            height: 'auto',
                            draggable: false,
                            adaptive: true,
                            scrollable: true,
                        }, {
                            'onPush': this.onPush
                        }
                    );
                });
            },

            searchLocations(query) {
                if(query.length > 1){
                    this.fetchLocations(query);
                }
            },

            fetchLocations(query) {
                axios.get('/api/locations/?query=' + query)
                    .then(({data}) => {
                        this.locations = data.locations;
                    });
            },
        },

        filters: {
            getTime: function (dateTime) {
                return this.$moment(dateTime).format('HH:mm');
            }
        }
    }
</script>


<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
    .disabled-picker {
        background:#cacac0
    }
</style>
