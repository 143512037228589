<template>
    <div class="modal-content">
        <div class="modal-header">
            <h4 class="modal-title">{{ title }}</h4>
            <button @click="$emit('close')" aria-label="Close" class="close" type="button">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <form class="form-horizontal">
                <div class="card-body">
                    <h4 v-text="setting.name"></h4>
                    <p v-text="setting.description"></p>
                    <!--Имя-->
                    <div class="form-group row">
                        <label class="col-sm-2 col-form-label">{{ 'Значення' }}</label>
                        <div class="col-sm-10" v-if="setting.field_type == 'string'">
                            <input autofocus
                                   class="form-control"
                                   id="value"
                                   placeholder="Значення"
                                   required
                                   type="text"
                                   v-model="setting.value"
                            >
                        </div>
                        <div class="col-sm-10" v-else-if="setting.field_type == 'boolean'">
                            <input type="checkbox" v-model="setting.value">
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <div class="modal-footer">
            <button class="btn btn-default" type="button"
                    @click="$emit('close')">
                {{ ('Закрити') }}
            </button>
            <button class="btn btn-info" type="button"
                    @click="save">
                {{ ('Зберегти') }}
            </button>
        </div>
    </div>
</template>

<script>

    export default {
        props: ['setting', 'title', 'loadData'],

        data() {
            return {
                errors: null
            }
        },

        mounted() {
            if (!this.setting) {
                return;
            }
        },

        methods: {
            save() {
                axios.patch(`/admin/settings/${this.setting.id}/update`, this.setting)
                    .then(({data}) => {
                        this.loadData();
                        this.$emit('close');
                    }).catch(e => {
                    return false;
                })
            }
        }
    }
</script>
