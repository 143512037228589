import VueRouter from 'vue-router';

let routes = [
    {
        path: '/users',
        component: require('./views/users/table').default,
    },
    {
        path: '/bookings',
        component: require('./views/bookings/table').default,
        alias: '/'
    },
    {
        path: '/routes',
        component: require('./views/routes/table').default,
        alias: '/'
    },
    {
        path: '/sheet',
        component: require('./views/sheet').default,
    },
    // {
    //     path: '/schedule:id',
    //     component: require('./views/schedules/Schedule').default,
    //     // children: [
    //     //     { path: 'list', component: List },
    //     //     { path: 'map', component: Map },
    //     //     { path: 'reserve', component: Reserve },
    //     // ]
    // },
    {
        path: '/schedules',
        component: require('./views/schedules/table').default,
    },
    {
        path: '/buses',
        component: require('./views/buses/table').default,
    },
    {
        path: '/clients',
        component: require('./views/clients/table').default,
    },
    {
        path: '/schemes',
        component: require('./views/schemes/table').default,
    },
    {
        path: '/carriers',
        component: require('./views/carriers/table').default,
    },
    {
        path: '/activities',
        component: require('./views/activities/table').default,
    },
    {
        path: '/settings',
        component: require('./views/settings/table').default
    },
    {
        path: '/orders',
        component: require('./views/orders/table').default
    }
];


export default new VueRouter({
    base: '/admin',
    mode: 'history',
    routes,
    linkActiveClass: 'active'
});
