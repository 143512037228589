<template>
    <div class="modal-content">
        <div class="modal-header">
            <h4 class="modal-title">{{ title }}</h4>
            <button @click="$emit('close')" aria-label="Close" class="close" type="button">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <form @keydown="form.errors.clear()" class="form-horizontal">
                <div class="card-body">

                    <!--Название перевозчика-->
                    <div class="form-group">
                        <label class="form-label">{{ ('Назва') }}</label>
                        <input class="form-control"
                               placeholder="Назва"
                               autofocus
                               type="text"
                               v-model="form.name"
                        >
                        <span class="invalid-feedback d-block"
                              role="alert"
                              v-if="form.errors.has('name')"
                              v-text="form.errors.get('name')"
                        />
                    </div>
                </div>
            </form>
        </div>

        <!--Create button-->
        <div class="modal-footer">
            <button
                class="btn btn-default" type="button"
                @click="$emit('close')">
                {{ ('Відміна') }}
            </button>
            <button
                v-if="!carrier"
                class="btn btn-info" type="button"
                :disabled="form.errors.any()"
                @click="create"
            >
                {{ ('Далі') }}
            </button>
            <button
                v-else
                class="btn btn-info" type="button"
                :disabled="form.errors.any()"
                @click="edit(carrier.id)"
            >
                {{ ('Змінити') }}
            </button>
        </div>
    </div>
</template>

<script>

    export default {
        props: ['carriers', 'title', 'carrier', 'loadData'],

        data() {
            return {
                form: new Form({
                    name: '',
                }),
            }
        },
        mounted() {
            if(!this.carrier) {
                return;
            }

            this.form = new Form(this.carrier);
        },

        methods: {
            getError(key) {
                return this.errors[key][0]; //return first error
            },
            hasError(key) {
                return _.has(this.errors, key);
            },

            create() {
                axios.post('/admin/carriers/create', this.form)
                    .then(() => {
                        this.loadData();
                        this.$emit('close');
                    }).catch(e => {
                    this.errors = e.response.data.errors;

                    return false;
                });
            },

            edit() {
                axios
                    .patch(`/admin/carriers/${this.form.id}}/update/`, this.form)
                    .then(({data}) => {
                        this.loadData();
                        this.$emit('close');
                    }).catch(e => {
                    this.errors = e.response.data.errors;

                    return false;
                });
            },
        }

    }
</script>
